export const plMainTranslations = {
  konfiguracja: "Konfiguracja",
  zadaszenia: "Zadaszenia",
  aktualnosci: "Aktualności",
  zamowienia: "Zamówienia",
  partnerzy: "Partnerzy",
  cennik: "Cennik",
  uzytkownicy: "Użytkownicy",
  niepoprawny_mail: "Wprowadź poprawny e-mail",
  brak_maila: "E-mail jest wymagany",
  brak_hasla: "Hasło jest wymagane",
  brak_starego_hasla: "Stare hasło jest wymagane",
  brak_nowego_hasla: "Nowe hasło jest wymagane",
  brak_potwierdzenia_hasla: "Potwierdzenie hasła jest wymagane",
  login_zacheta: "Wprowadź swój adres e-mail i hasło, aby się zalogować.",
  login: "Zaloguj",
  mail: "E-mail",
  haslo: "Hasło",
  stare_haslo: "Stare hasło",
  nowe_haslo: "Nowe hasło",
  potwierdz_haslo: "Potwierdź hasło",
  niepoprawne_dane: "Niepoprawne dane",
  blad_uwierzytelniania: "Sesja wygasła",
  nieodnaleziono: "Nie odnaleziono zasobu",
  blad_serwera: "Błąd serwera",
  wystapil_nieoczekiwany_blad: "Wystąpił nieoczekiwany błąd",
  nieoczekiwany_blad_tresc:
    "Podczas przetwarzania polecenia wystąpił nieoczekiwany błąd. Jeśli po odświeżeniu strony błąd nadal będzie występował, skontaktuj się ze wsparciem, podając identyfikator błędu.",
  identyfikator_bledu: "Identyfikator błędu",
  zamknij: "Zamknij",
  ups: "Ups... Coś poszło nie tak",
  ups_tresc:
    "Ta strona nie mogła załadować się prawidłowo. Jeśli po odświeżeniu strony błąd nadal występuje, skontaktuj się ze wsparciem.",
  zmiana_hasla_zacheta: "Ustaw nowe hasło do swojego konta",
  zapisz: "Zapisz",
  powrot: "Powrót",
  rozne_hasla: "Podane hasła są różne",
  haslo_min: "Hasło nie może być krótsze niż 8 znaków",
  haslo_max: "Hasło nie może być dłuższe niż 32 znaki",
  slabe_haslo:
    "Hasło musi zawierać małą literę, dużą literę, cyfrę oraz znak specjalny",
  polityka_prywatnosci: "Polityka prywatności",
  zaakceptuj: "Zaakceptuj",
  anuluj: "Anuluj",
  polityka_prywatnosci_tekst: "Polityka prywatności",
  liczba_wierszy: "Liczba wierszy",
  status: "Status",
  aktywny: "Aktywny",
  dezaktywowany: "Dezaktywowany",
  kraj: "Kraj",
  dodaj: "Dodaj",
  empty_table: "Brak danych dla podanych parametrów",
  edytuj: "Edytuj",
  aktywuj: "Aktywuj",
  dezaktywuj: "Dezaktywuj",
  nazwa: "Nazwa",
  adres: "Adres",
  znizka: "Zniżka",
  potwierdz_operacje: "Potwierdź operację",
  dezaktywacja_partnera_pytanie:
    "Czy na pewno chcesz dezaktywować tego partnera?",
  aktywacja_partnera_pytanie: "Czy na pewno chcesz aktywować tego partnera?",
  edycja_partnera: "Edycja partnera",
  nowy_partner: "Nowy partner",
  dane_partnera: "Dane partnera",
  kierunkowy: "Kierunkowy",
  numer_telefonu: "Numer telefonu",
  darmowe_przedluzenie_szyn: "Darmowe przedłużenie szyn",
  dane_adresowe: "Dane adresowe",
  ulica: "Ulica",
  numer_domu: "Numer domu",
  numer_lokalu: "Numer lokalu",
  kod_pocztowy: "Kod pocztowy",
  miasto: "Miasto",
  wymagane_pole: "To pole jest wymagane",
  niepoprawna_wartosc: "Niepoprawna wartość",
  zapisane_zmiany: "Zmiany zostały zapisane",
  dezaktywacja_uzytkwnika_pytanie:
    "Czy na pewno chcesz dezaktywować tego użytkownika?",
  aktywacja_uzytkownika_pytanie:
    "Czy na pewno chcesz aktywować tego użytkownika?",
  rola: "Rola",
  ROLE_ADMIN: "Admin",
  ROLE_PARTNER: "Partner",
  brak: "Brak",
  edycja_usera: "Edycja użytkownika",
  nowy_user: "Nowy użytkownik",
  dane_usera: "Dane użytkownika",
  imie: "Imię",
  nazwisko: "Nazwisko",
  partner: "Partner",
  data_dodania: "Data",
  tytul: "Tytuł",
  tresc: "Treść",
  dezaktywacja_posta_pytanie: "Czy na pewno chcesz dezaktywować to ogłoszenie?",
  aktywacja_posta_pytanie: "Czy na pewno chcesz aktywować to ogłoszenie?",
  polski: "Polski",
  angielski: "Angielski",
  niemiecki: "Niemiecki",
  szwedzki: "Szwedzki",
  dane_podstawowe: "Dane podstawowe",
  nowy_post: "Nowe ogłoszenie",
  edytcja_posta: "Edycja ogłoszenia",
  kolory: "Kolory",
  blokady: "Blokady",
  wypelnienia: "Wypełnienia",
  kategorie: "Kategorie",
  profile: "Profile",
  edycja_blokady: "Edycja blokady",
  nowa_blokada: "Nowa blokada",
  polska_nazwa: "Polska nazwa",
  angielska_nazwa: "Angielska nazwa",
  niemiecka_nazwa: "Niemiecka nazwa",
  szwedzka_nazwa: "Szwedzka nazwa",
  dezaktywacja_blokady_pytanie: "Czy na pewno chcesz dezaktywować tę blokadę?",
  aktywacja_blokady_pytanie: "Czy na pewno chcesz aktywować tę blokadę?",
  __edycja_koloru: "Edycja koloru",
  __nowy_kolor: "Nowy kolor",
  __dezaktywacja_koloru_pytanie: "Czy na pewno chcesz dezaktywować ten kolor?",
  __aktywacja_koloru_pytanie: "Czy na pewno chcesz aktywować ten kolor?",
  __edycja_wypelnienia: "Edycja wypełnienia",
  __nowe_wypelnienie: "Nowe wypełnienie",
  __dezaktywacja_wypelnienia_pytanie:
    "Czy na pewno chcesz dezaktywować to wypełnienie?",
  __aktywacja_wypelnienia_pytanie:
    "Czy na pewno chcesz aktywować to wypełnienie?",
  __edycja_profilu: "Edycja profilu",
  __nowy_profil: "Nowy profil",
  __dezaktywacja_profilu_pytanie:
    "Czy na pewno chcesz dezaktywować ten profil?",
  __aktywacja_profilu_pytanie: "Czy na pewno chcesz aktywować ten profil?",
  __edycja_kategorii: "Edycja kategorii",
  __nowa_kategoria: "Nowa kategoria",
  __dezaktywacja_kategorii_pytanie:
    "Czy na pewno chcesz dezaktywować tę kategorię?",
  __aktywacja_kategorii_pytanie: "Czy na pewno chcesz aktywować tę kategorię?",
  __cena__przedluzenia_w_profilu: "Cena za metr przedłużenia szyn",
  __dostepne_blokady: "Dostępne blokady",
  __wszystkie_blokady: "Wszystkie blokady",
  __brak_opcji: "Brak opcji",
  __wybierz_dostepne_blokady: "Wybierz dostępne blokady",
  __dostepne_kolory: "Dostępne kolory",
  __wszystkie_kolory: "Wszystkie kolory",
  __wybierz_dostepne_kolory: "Wybierz dostępne kolory",
  __dostepne_wypelnienia: "Dostępne wypełnienia",
  __wszystkie_wypelnienia: "Wszystkie wypełnienia",
  __wybierz_dostepne_wypelnienia: "Wybierz dostępne wypełnienia",
  __dostepne_drzwi: "Dostępne drzwi",
  __wszystkie_drzwi: "Wszystkie drzwi",
  __drzwi: "Drzwi",
  __wybierz_dostepne_drzwi: "Wybierz dostępne drzwi",
  FRONT_MAIN: "Przednie drzwi",
  BACK_MAIN: "Tylne drzwi",
  FRONT_RIGHT: "Przednie prawe",
  FRONT_LEFT: "Przednie lewe",
  BACK_RIGHT: "Tylne prawe",
  BACK_LEFT: "Tylne lewe",
  __cena: "Cena",
  DOOR: "Drzwi",
  __ceny_podstawowe: "Ceny podstawowe",
  __edycja_ceny: "Edycja ceny",
  __dodawanie_ceny_moduly: "Nowa cena zależna od modułów",
  __edycja_ceny_moduly: "Edycja ceny zależnej od liczby modułów",
  __liczba_modulow: "Liczba modułów",
  __cena_blokady: "Cena blokady",
  __cena_montazu: "Cena montażu",
  __ceny_moduly: "Ceny zależne od liczby modułów",
  FRONT: "Przednia klapa",
  BACK: "Tylna klapa",
  __dostepne_klapy: "Dostępne klapy",
  __wszystkie_klapy: "Wszystkie klapy",
  __klapy: "Klapy",
  __wybierz_dostepne_klapy: "Wybierz dostępne klapy",
  __edycja_zadaszenia: "Edycja zadaszenia",
  __nowe_zadaszenie: "Nowe zadaszenie",
  __warianty_zadaszenia: "Warianty zadaszenia",
  __rendery_zadaszenia: "Rendery zadaszenia",
  __pozycja_na_liscie: "Pozycja na liście",
  __kategoria: "Kategoria",
  __profil: "Profil",
  __liczba_szyn: "Liczba szyn",
  __domyslny: "Domyślny",
  __z_drzwiami: "Z drzwiami",
  __z_klapa: "Z klapą",
  __kliknij_zdjecie: "Kliknij, by wybrać zdjęcie",
  __nowy_wariant: "Nowy wariant",
  __edycja_wariantu: "Edycja wariantu",
  __szerokosc_zewnetrzna: "Szer. zewnętrzna",
  __szerokosc_wewnetrzna: "Szer. wewnętrzna",
  __szerokosc_zewnetrzna_long: "Szerokość zewnętrzna",
  __szerokosc_wewnetrzna_long: "Szerokość wewnętrzna",
  __dlugosc: "Długość",
  __wysokosc: "Wysokość",
  __standardowa_dlugosc: "Standardowa długość",
  __model: "Model",
  __ceny_wariantu: "Ceny wariantu",
  __wszystkie_istniejace: "Wszystkie istniejące",
  __wszystkie_z_katerogii: "Wszystkie dostępne w kategorii",
  __wszystkie_z_modelu: "Wszystkie dostępne w modelu",
  __brak_dostepnych_wypelnien: "Brak dostępnych wypełnień",
  __wypelnienie: "Wypełnienie",
  __aktywna: "Aktywna",
  __dezaktywowana: "Dezaktywowana",
  __aktywacja_variantu_pytanie: "Czy na pewno chcesz aktywować ten wariant?",
  __dezaktywacja_variantu_pytanie:
    "Czy na pewno chcesz dezaktywować ten wariant?",
  __moduly: "Moduły",
  __aktywacja_zadaszenia_pytanie:
    "Czy na pewno chcesz aktywować to zadaszenie?",
  __dezaktywacja_zadaszenia_pytanie:
    "Czy na pewno chcesz dezaktywować to zadaszenie?",
  __niezapisane_zmiany: "Niezapisane zmiany",
  __porzuc_zmiany: "Cofnij",
  __porzucone_zmiany_pytanie:
    "W formularzu są niezapisane zmiany, czy chcesz je porzucić?",
  TRANSPORT_WITHOUT_MONTAGE: "Transport bez montażu (cena za km)",
  TRANSPORT_WITH_MONTAGE: "Transport z monatażem (cena za km)",
  __wybor_modelu: "Wybór modelu",
  __nowa_oferta: "Nowa oferta",
  __wybrany_model: "Wybrany model",
  __wymiary_zadaszenia: "Wymiary zadaszenia",
  __dlugosci_niestandardowe: "Długości niestandardowe",
  __konfiguracja_modelu: "Konfiguracja modelu",
  __kolor: "Kolor",
  __przedluzenie_szyn: "Przedłużenie szyn",
  __blokada: "Blokada",
  __rodzaj_podloza: "Rodzaj podłoża",
  __domyslne_podwyzszenie_scian: "Domyślne podwyższenie ścian",
  __podwyzszenie_scian: "Podwyższenie ścian",
  __standardowe: "Standard",
  __kluczyk_blokady: "Kluczyk blokady",
  __podwyzszenie_scian_tooltip:
    "Wybranie podwyższenia ścian innego niż standardowe nie wypływa na cenę. Blokuje natomiat opcję wyboru klapy na ścianie przedniej i tylnej. Jeśli została wybrana klapa na ścienie przedniej lub tylniej, opcja zmiany prześwitu zostaje zablokowana.",
  __dane_klienta: "Dane klienta",
  __oferta_dla_klienta: "Oferta dla klienta",
  __konfiguracja_drzwi: "Konfiguracja drzwi",
  __sciana_przednia: "Ściana przednia",
  __sciana_tylnia: "Ściana tylna",
  __gora_zadaszenia: "Góra zadaszenia",
  __drzwi_na_dachu_tooltip:
    "Zadaszenia posiadają jedne drzwi w cenie: na największym module lub na ścianie. Kolejne konfiguracje drzwi są dodatkowo płatne. Wyjątkami są modele Gravity oraz Cosmos, które nie posiadają drzwi.",
  __montaz_i_transport: "Montaż i transport",
  __dostawa: "Dostawa",
  __obior_osobisty: "Odbiór osobisty",
  __transport_bucovers: "Transport Bucovers",
  __montaz_transport_bucovers: "Transport i montaż Bucovers",
  __odleglosc: "Odległość",
  __szczegoly_platnosci: "Szczegóły płatności",
  __przyznany_rabat: "Przyznany rabat",
  __tooltip_rabat: "Rabat nie obejmuje transportu oraz montażu.",
  __cena_przed_rabatem: "Cena netto Bucovers przed rabatem",
  __cena_po_rabacie: "Cena netto Bucovers po rabacie",
  __cena_dla_klienta_przed_rabatem: "Cena netto dla klienta przed rabatem",
  __rabat_dla_klienta: "Rabat dla klienta",
  __cena_dla_klienta_po_rabacie: "Cena netto dla klienta po rabacie",
  __wszystkie: "Wszystkie",
  __oferty: "Oferty",
  WAITING_FOR_ACCEPT: "Oczekujące na akceptację",
  ACCEPTED: "Zaakceptowane",
  DELIVERED: "Dostarczone",
  CANCELED: "Anulowane",
  DELETED: "Usunięte",
  EXPIRED: "Przeterminowane",
  __klient: "Klient",
  __numer_oferty: "Numer",
  __data_utworzenia: "Data",
  __szczegoly: "Szczegóły",
  __zamow: "Zamów",
  __termin_dostawy: "Wstępny termin dostawy",
  OFFER: "Oferta",
  __szczegoly_oferty: "Szczegóły oferty nr",
  __szczegoly_zamowienia: "Szczegóły zamówienia nr",
  __nowe_zamowienie: "Nowe zamówienie",
  __usun: "Usuń",
  __usunac_oferte: "Czy na pewno chcesz usunąć oferte nr",
  __anulowac_zamowienie: "Czy na pewno chcesz anulować zamówienie nr",
  __tak: "Tak",
  BOX_MONTAGE: "Złożenie boxa",
  __rzut_zadaszenia: "Rzut zadaszenia",
  __pobierz: "Pobierz",
  __export_header: "Bucovers – Producent Zadaszeń Basenowych",
  __oferta_przygotowana_przez: "Oferta została przygotowana przez",
  __oferta_wazna: "Oferta ważna",
  __dni: "dni",
  __dostawca: "Dostawca",
  __odbiorca: "Odbiorca",
  __model_zadaszanie: "Model zadaszenia",
  __oznaczenie_produkcji: "Oznaczenie produkcji",
  __dlugosc_zadaszenia: "Długość zadaszenia",
  __wysokosc_zadaszenia: "Wysokość zadaszenia",
  __rozstaw_wewnetrzny: "Rozstaw wewnętrzny szyn",
  __rozstaw_zewnetrzny: "Rozstaw zewnętrzny szyn",
  __szerokosc_szyn: "Szerokość szyn",
  __podwyzszenie_sciany: "Podwyższenie ściany",
  __klapa: "Klapa",
  __komentarze: "Komentarze",
  __specyfikacja_partner: "Specyfikacja dla partnera",
  __specyfikacja_klient: "Specyfikacja dla klienta",
  __cena_netto: "Cena netto",
  __rabat: "Rabat",
  __cena_netto_po_rabacie: "Cena netto po rabacie",
  __opcje_dodatkowe: "Opcje dodatkowe",
  __podsumowanie: "Podsumowanie",
  __dodatkowe_drzwi: "Dodatkowe drzwi",
  __montaz: "Montaż",
  __transport: "Transport",
  __podziekowanie: "Dziękujemy za zainteresowanie naszą ofertą",
  __potwierdzenie_zamowienia: "Potwierdzenie zamówienia przez klienta",
  __pieczatka: "Pieczątka, data i podpis",
  __ulica_skrot: "ul.",
  __pozycja_szyny: "Pozycja szyny",
  RIGHT: "Prawa",
  LEFT: "Lewa",
  __tel: "tel.",
  __zmiana_statusu: "Zmiana statusu zamówienia nr",
  __z: "z",
  __na: "na",
  __brak_powiadomien: "Brak powiadomień",
  __zmiany: "Wprowadzono zmiany",
  __box: "Box",
  __zlozenie: "Złożenie zadaszenia",
  __przedluzenie_tooltip:
    "Zadaszenia w kategorii box posiadają w cenie przedłużenie szyn o 220 cm. Opłata zostanie naliczona wyłącznie od długości powyżej 220 cm.",
  __zlozenie_tooltip:
    "Jeśli zostanie wybrana opcja montażu zadaszenia, złożenie zadaszenia zostanie wybrane automatycznie.",
  __globalna_zmiana: "Globalna zmiana cen",
  __zmien: "Zmień",
  __procentowa_zmiana: "Wartość procentowa zmiany",
  __powieksz: "Powiększenie",
  __zmniejsz: "Zmniejszenie",
  __zaznacz_wszystkie: "Zaznacz wszystkie",
  __waluta_klienta: "Waluta klienta",
  __stawka_vat: "Stawka VAT",
  __cena_brutto: "Cena brutto dla klienta",
  EUR: "Euro",
  PLN: "Złoty",
  GBP: "Funt szterling",
  SEK: "Korona szwedzka",
  NOK: "Korona norweska",
  USD: "Dolar amerykański",
  CHF: "Frank szwajcarski",
  CZK: "Korona czeska",
  HUF: "Forint",
  DKK: "Korona duńska",
  __cena_brutto_rabat: "Cena brutto po rabacie",
  __brak_wymaganych_danych: "Brak wymaganych danych",
  __uzupelnij_brakujace_dane:
    "W celu zapisania formularza uzupełnij brakujące dane.",
  __cena_nie_uwzglednia:
    "Cena nie uwzględnia przepraw promowych oraz innych opłat specjalnych.",
  __rzut_blad:
    "Przedłużenie szyn jest zbyt duże, aby wygenerować rzut zadaszenia. Wybierz przedłużenie szyn nie większe niż 1000 cm.",
  __koszt_montazu: "koszt montażu",
  __dodane_przez: "Dodane przez",
  __termin_tooltip:
    "Zamówienie zostanie dostarczone w tygodniu zawierającym wybraną datę.",
  __eksport_dla_klienta: "Eksport dla klienta",
  __eksport_dla_klienta_bucovers: "Eksport dla klienta Bucovers",
  __eksport_bucovers: "Eksport Bucovers",
  __rodzaj_eksportu: "Wybierz rodzaj eksportu",
  __eksport_bucovers_opis:
    "Eksport Bucovers zawiera kompleksowe informajce dotyczące oferty przeznaczone dla partnera biznesowego.",
  __eksport_dla_klienta_opis:
    "Eksport dla klienta został przygotowany z myślą o klientach partnerów biznesowych.",
  __eksport_dla_klienta_bucovers_opis:
    "Eksport dla klienta Bucovers to dokument przygotowany dla detalicznych klientów Bucovers.",
  __druga_wysokosc: "Dodatkowa wysokość",
  __dodatkowe_zdjecia: "Dodatkowe rendery",
  __dodatkowe_z_drzwiami: "Dodatkowy z drzwiami",
  __dodatkowe_z_klapa: "Dodatkowy z klapą",
  __rozbicie_ceny: "Rozbicie ceny",
  __cena_wariantu_dla_klienta_przed_rabatem:
    "Cena netto wariantu dla klienta przed rabatem",
  __rabat_na_wariant_dla_klienta: "Rabat na wariant dla klienta",
  __cena_wariantu_dla_klienta_po_rabacie:
    "Cena netto wariantu dla klienta po rabacie",
  __cena_przedluzenia_szyn_dla_klienta_przed_rabatem:
    "Cena netto przedłużenia szyn dla klienta przed rabatem",
  __rabat_na_przedluzenie_szyn_dla_klienta:
    "Rabat na przedłużenie szyn dla klienta",
  __cena_przedluzenia_szyn_dla_klienta_po_rabacie:
    "Cena netto przedłużenia szyn dla klienta po rabacie",
  __cena_kluczyka_blokady_dla_klienta_przed_rabatem:
    "Cena netto kluczyka blokady dla klienta przed rabatem",
  __rabat_na_kluczyk_blokady_dla_klienta:
    "Rabat na kluczyk blokady dla klienta",
  __cena_kluczyka_blokady_dla_klienta_po_rabacie:
    "Cena netto kluczyka blokady dla klienta po rabacie",
  __cena_drzwi_dla_klienta_przed_rabatem:
    "Cena netto drzwi dla klienta przed rabatem",
  __rabat_na_drzwi_dla_klienta: "Rabat na drzwi dla klienta",
  __cena_drzwi_dla_klienta_po_rabacie:
    "Cena netto drzwi dla klienta po rabacie",
  __cena_transportu_dla_klienta_przed_rabatem:
    "Cena netto transportu dla klienta przed rabatem",
  __rabat_na_transport_dla_klienta: "Rabat na transport dla klienta",
  __cena_transportu_dla_klienta_po_rabacie:
    "Cena netto transportu dla klienta po rabacie",
  __cena_montazu_dla_klienta_przed_rabatem:
    "Cena netto montażu dla klienta przed rabatem",
  __rabat_na_montaz_dla_klienta: "Rabat na montaż dla klienta",
  __cena_montazu_dla_klienta_po_rabacie:
    "Cena netto montażu dla klienta po rabacie",
  __cena_zlozenia_zadaszenia_dla_klienta_przed_rabatem:
    "Cena netto złożenia zadaszenia dla klienta przed rabatem",
  __rabat_na_zlozenie_zadaszenia_dla_klienta:
    "Rabat na złożenie zadaszenia dla klienta",
  __cena_zlozenia_zadaszenia_dla_klienta_po_rabacie:
    "Cena netto złożenia zadaszenia dla klienta po rabacie",
  __cena_dla_klienta: "Cena netto dla klienta",
};
