export const svMainTranslations = {
  konfiguracja: "Konfigurering",
  zadaszenia: "Pooltak",
  aktualnosci: "Nyheter",
  zamowienia: "Beställningar",
  partnerzy: "Partners",
  cennik: "Prislista",
  uzytkownicy: "Användare",
  niepoprawny_mail: "Vänlige ange en giltid mailadress",
  brak_maila: "Mailadress krävs",
  brak_hasla: "Lösenord krävs",
  brak_starego_hasla: "Gammalt lösenord krävs",
  brak_nowego_hasla: "Nytt lösenord krävs",
  brak_potwierdzenia_hasla: "Lösenord bekräftele krävs",
  login_zacheta: "Skriv ditt mail och lösenord för att logga in",
  login: "Logga in",
  mail: "Mailadress krävs",
  haslo: "Lösenord",
  stare_haslo: "Gammalt lösenord",
  nowe_haslo: "Nytt lösenord",
  potwierdz_haslo: "Bekräfta lösenord",
  niepoprawne_dane: "Felaktigt info",
  blad_uwierzytelniania: "Sessinen har löpt ut",
  nieodnaleziono: "Informationen hittades inte",
  blad_serwera: "Server fel",
  wystapil_nieoczekiwany_blad: "Fel har inträffat",
  nieoczekiwany_blad_tresc: "Fel har inträffat. Uppge felen kod",
  identyfikator_bledu: "Fel ID",
  zamknij: "Stäng av",
  ups: "Det gick inte så bra",
  ups_tresc: "Fel.Kontakta administrator",
  zmiana_hasla_zacheta: "Skriv in nytt lösenord",
  zapisz: "Spara",
  powrot: "Tillbaka",
  rozne_hasla: "Lösenord är olika",
  haslo_min: "Lösenord får inte var kortar än 8 täcken",
  haslo_max: "Lösenord kan inte vara längre än 32",
  slabe_haslo: "Lösenord måste innehålla stora bokstäver och siffror",
  polityka_prywatnosci: "Integritet policy",
  zaakceptuj: "Godkänn",
  anuluj: "Annulera",
  polityka_prywatnosci_tekst: "Polityka prywartości",
  liczba_wierszy: "Antal rader",
  status: "Status",
  aktywny: "Aktiv",
  dezaktywowany: "Inaktiv",
  kraj: "Land",
  dodaj: "Lägg till",
  empty_table: "Data saknas",
  edytuj: "Redigera",
  aktywuj: "Aktivera",
  dezaktywuj: "Inaktivera",
  nazwa: "Benämning",
  adres: "Adress",
  znizka: "Rabatt",
  potwierdz_operacje: "Bekräfta operation",
  dezaktywacja_partnera_pytanie: "Vill du inaktivera din partner",
  aktywacja_partnera_pytanie: "Vill Du aktuvere din partner",
  edycja_partnera: "Edit din partner",
  nowy_partner: "Ny patner",
  dane_partnera: "Info om din partner",
  kierunkowy: "Telefon rikt nummer",
  numer_telefonu: "Telefon nummer",
  darmowe_przedluzenie_szyn: "Förlänga skenor",
  dane_adresowe: "Adress",
  ulica: "Gata",
  numer_domu: "Nummer",
  numer_lokalu: "Lägenhets nummer",
  kod_pocztowy: "Postkod",
  miasto: "Stad",
  wymagane_pole: "Måste fyllas",
  niepoprawna_wartosc: "Felaktigt värde",
  zapisane_zmiany: "Ändringar noterades",
  dezaktywacja_uzytkwnika_pytanie:
    "Är du säkert att avaktivera denna användare",
  aktywacja_uzytkownika_pytanie: "Är du säkert att aktivera denna användare",
  rola: "Din roll",
  ROLE_ADMIN: "Admin",
  ROLE_PARTNER: "Partner",
  brak: "Finns ej",
  edycja_usera: "Edit användare",
  nowy_user: "Ny användare",
  dane_usera: "Info om användare",
  imie: "Förnamn",
  nazwisko: "Namn",
  partner: "Partner",
  data_dodania: "Datum",
  tytul: "Titel",
  tresc: "Innehål",
  dezaktywacja_posta_pytanie: "Är du säkert att du vil avaktivera denna annons",
  aktywacja_posta_pytanie: "Är du säkert att aktivera denna annons",
  polski: "Polska",
  angielski: "Engelska",
  niemiecki: "Tyska",
  szwedzki: "Svenska",
  dane_podstawowe: "Gundläggande info",
  nowy_post: "Ny annons",
  edytcja_posta: "Edit annons",
  kolory: "Färger",
  blokady: "Blockad",
  wypelnienia: "Täckning av produkten",
  kategorie: "Kategori",
  profile: "Vilka pofiler",
  edycja_blokady: "Edit blockad",
  nowa_blokada: "Ny blockad",
  polska_nazwa: "Polsk benämning",
  angielska_nazwa: "Engelsk benämning",
  niemiecka_nazwa: "Tysk benämning",
  szwedzka_nazwa: "Svensk benämning",
  dezaktywacja_blokady_pytanie: "Vill du ta bort denna blockad",
  aktywacja_blokady_pytanie: "Vill du aktivera denna blockad",
  __edycja_koloru: "Edit färg",
  __nowy_kolor: "Ny färg",
  __dezaktywacja_koloru_pytanie: "Vil du inaktivera denna färg?",
  __aktywacja_koloru_pytanie: "Vill du aktivera denna färg?",
  __edycja_wypelnienia: "Edit täckning",
  __nowe_wypelnienie: "Ny täckning",
  __dezaktywacja_wypelnienia_pytanie: "Vill inaktivera denna täckning?",
  __aktywacja_wypelnienia_pytanie: "Vill du aktivera det ?",
  __edycja_profilu: "Editera profilen",
  __nowy_profil: "Ny användare profil",
  __dezaktywacja_profilu_pytanie: "Vill du avaktivera denna profilen ?",
  __aktywacja_profilu_pytanie: "Vill du aktivera denna profilen ?",
  __edycja_kategorii: "Redigera kategori",
  __nowa_kategoria: "Ny kategori",
  __dezaktywacja_kategorii_pytanie: "Vill du avaktivera denna kategori ?",
  __aktywacja_kategorii_pytanie: "Vill du aktivera denna kategori ?",
  __cena__przedluzenia_w_profilu: "Pris per extra skenan",
  __dostepne_blokady: "Tillgängliga blockeringar",
  __wszystkie_blokady: "Alla blockeringar och lås",
  __brak_opcji: "Ingen option",
  __wybierz_dostepne_blokady: "Välj tillgängliga lås",
  __dostepne_kolory: "Tillgängliga färger",
  __wszystkie_kolory: "Alla färger",
  __wybierz_dostepne_kolory: "Välj tillgängliga färger",
  __dostepne_wypelnienia: "Tillgägliga täckningar",
  __wszystkie_wypelnienia: "Samtliga täckningar",
  __wybierz_dostepne_wypelnienia: "Välj täckning av sektion",
  __dostepne_drzwi: "Tillgängliga dörrar",
  __wszystkie_drzwi: "Alla dörrar",
  __drzwi: "Dörrar",
  __wybierz_dostepne_drzwi: "Välj dörr",
  FRONT_MAIN: "Frontdörr",
  BACK_MAIN: "Bakdörr",
  FRONT_RIGHT: "Frontdörr höger",
  FRONT_LEFT: "Frontdörr vänster",
  BACK_RIGHT: "Bakdörr höger",
  BACK_LEFT: "Bakdörr vänster",
  __cena: "Pris",
  DOOR: "Dörr",
  __ceny_podstawowe: "Baspris",
  __edycja_ceny: "Redigera pris",
  __dodawanie_ceny_moduly: "Nytt pris beroende på sektioner",
  __edycja_ceny_moduly: "Pris beroende på antal sektioner",
  __liczba_modulow: "Antal sektioner",
  __cena_blokady: "Lås pris",
  __cena_montazu: "Installation pris",
  __ceny_moduly: "Pris beroende på antalet sektioner",
  FRONT: "Öppningsbar frontklaff",
  BACK: "Öppningsbar baklaff",
  __dostepne_klapy: "Tillängliga klaffar",
  __wszystkie_klapy: "Alla klaffar",
  __klapy: "Klaffar",
  __wybierz_dostepne_klapy: "Välj klaffar",
  __edycja_zadaszenia: "Redigera pooltaket",
  __nowe_zadaszenie: "Nytt pooltak",
  __warianty_zadaszenia: "Pooltaks model",
  __rendery_zadaszenia: "Pooltaks render",
  __pozycja_na_liscie: "Position i listan",
  __kategoria: "Kategori",
  __profil: "Profiler",
  __liczba_szyn: "Antal skenor",
  __domyslny: "Standard",
  __z_drzwiami: "Med dörr ?",
  __z_klapa: "Med klaff ?",
  __kliknij_zdjecie: "Klick för att vällja fotot",
  __nowy_wariant: "Ny variant",
  __edycja_wariantu: "Redigera modelen",
  __szerokosc_zewnetrzna: "Yttre bredd",
  __szerokosc_wewnetrzna: "Innermått",
  __szerokosc_zewnetrzna_long: "Yttre bredd",
  __szerokosc_wewnetrzna_long: "Innermått",
  __dlugosc: "Längd",
  __wysokosc: "Höjd",
  __standardowa_dlugosc: "Standard längd",
  __model: "Model",
  __ceny_wariantu: "Pris",
  __wszystkie_istniejace: "Alla existerande",
  __wszystkie_z_katerogii: "Alle tillgängliga i denna kategori",
  __wszystkie_z_modelu: "Alla tillgängliga i denna model",
  __brak_dostepnych_wypelnien: "Finns inte sådana täckningar",
  __wypelnienie: "Täckning av sektion",
  __aktywna: "Aktiv",
  __dezaktywowana: "Avaktiverad",
  __aktywacja_variantu_pytanie: "Vill du aktivera denna model ?",
  __dezaktywacja_variantu_pytanie: "Vill du avaktivera denna model ?",
  __moduly: "Sektioner",
  __aktywacja_zadaszenia_pytanie:
    "Vill du verkligen aktivera detta pooltaket ?",
  __dezaktywacja_zadaszenia_pytanie:
    "Vill du verkligen avaktivera detta pooltaket ?",
  __niezapisane_zmiany: "Icke sparade ändringar",
  __porzuc_zmiany: "Backa",
  __porzucone_zmiany_pytanie:
    "Formuläret innehåller osparade data, vill du verkligen överge det?",
  TRANSPORT_WITHOUT_MONTAGE: "Transport utan montering (pris per km)",
  TRANSPORT_WITH_MONTAGE: "Transport med montering (pris per km)",
  __wybor_modelu: "Välj modell",
  __nowa_oferta: "Nytt erbjudande",
  __wybrany_model: "Vald modell",
  __wymiary_zadaszenia: "Mått på skärmtak",
  __dlugosci_niestandardowe: "Icke-standardiserade längder",
  __konfiguracja_modelu: "Modellkonfiguration",
  __kolor: "Färg",
  __przedluzenie_szyn: "Förlängning av skena",
  __blokada: "Lås",
  __rodzaj_podloza: "Typ av bas",
  __domyslne_podwyzszenie_scian: "Standard vägghöjdsförlängning",
  __podwyzszenie_scian: "Vägghöjdsförlängning",
  __standardowe: "Standard",
  __kluczyk_blokady: "Låsnyckel",
  __podwyzszenie_scian_tooltip:
    "Valet av en vägghöjdsförlängning som skiljer sig från standarden påverkar inte priset. Det blockerar dock möjligheten att välja en främre eller bakre vägglucka. Om en främre eller bakre vägglucka väljs blockeras alternativet att ändra markfrigången.",
  __dane_klienta: "Kunduppgifter",
  __oferta_dla_klienta: "Erbjudande för kunden",
  __konfiguracja_drzwi: "Dörrkonfiguration",
  __sciana_przednia: "Framvägg",
  __sciana_tylnia: "Bakre vägg",
  __gora_zadaszenia: "Överdel av skärmskärmtaket",
  __drzwi_na_dachu_tooltip:
    "I priset för skärmtak ingår en enda dörr: i den största modulen eller väggen. Efterföljande dörrkonfigurationer betalas extra. Det enda undantaget gäller modellerna Gravity och Cosmos - de innehåller inga dörrar.",
  __montaz_i_transport: "Montering och transport",
  __dostawa: "Leverans",
  __obior_osobisty: "Personlig hämtning",
  __transport_bucovers: "Transport av Bucovers",
  __montaz_transport_bucovers: "Transport och montering av Bucovers",
  __odleglosc: "Avstånd",
  __szczegoly_platnosci: "Betalningsuppgifter",
  __przyznany_rabat: "Rabatt som ges",
  __tooltip_rabat: "Rabatten täcker inte transport- eller monteringskostnader",
  __cena_przed_rabatem: "Bucovers nettopris före rabatt",
  __cena_po_rabacie: "Bucovers nettopris efter rabatt",
  __cena_dla_klienta_przed_rabatem: "Kundens nettopris före rabatt",
  __rabat_dla_klienta: "Kundrabatt",
  __cena_dla_klienta_po_rabacie: "Kundens nettopris efter rabatt",
  __wszystkie: "Alla",
  __oferty: "Erbjudanden",
  WAITING_FOR_ACCEPT: "I väntan på godkännande",
  ACCEPTED: "Accepterat",
  DELIVERED: "Levererat",
  CANCELED: "Avbokad",
  DELETED: "Radad",
  EXPIRED: "Förfallet",
  __klient: "Kund",
  __numer_oferty: "Nummer",
  __data_utworzenia: "Datum",
  __szczegoly: "Uppgifter",
  __zamow: "Beställning",
  __termin_dostawy: "Preliminärt leveransdatum",
  OFFER: "Erbjudande",
  __szczegoly_oferty: "Uppgifter om erbjudandet nr.",
  __szczegoly_zamowienia: "Uppgifter om beställning nr.",
  __nowe_zamowienie: "Ny beställning",
  __usun: "Ta bort",
  __usunac_oferte: "Vill du verkligen radera erbjudandet nr.",
  __anulowac_zamowienie: "Vill du verkligen avbryta beställning nr.",
  __tak: "Ja",
  BOX_MONTAGE: "Montering av lådan",
  __rzut_zadaszenia: "Skiss över skärmtaket",
  __pobierz: "Ladda ner",
  __export_header: "Bucovers - Tillverkare av poolskärmtak",
  __oferta_przygotowana_przez: "Erbjudandet har utarbetats av",
  __oferta_wazna: "Erbjudandet gäller för",
  __dni: "dagar",
  __dostawca: "Leverantör",
  __odbiorca: "Mottagare",
  __model_zadaszanie: "Skärmtakmodell",
  __oznaczenie_produkcji: "Produktionsbeteckning",
  __dlugosc_zadaszenia: "Skärmtakskärmtakslängd",
  __wysokosc_zadaszenia: "Höjd på skärmtaket",
  __rozstaw_wewnetrzny: "Inre skenavstånd",
  __rozstaw_zewnetrzny: "Yttre skenavstånd",
  __szerokosc_szyn: "Bredd på skenan",
  __podwyzszenie_sciany: "Förlängning av väggens höjd",
  __klapa: "Luckor",
  __komentarze: "Kommentarer",
  __specyfikacja_partner: "Specifikation för partner",
  __specyfikacja_klient: "Kundspecifikation",
  __cena_netto: "Nettopris",
  __rabat: "Rabatt",
  __cena_netto_po_rabacie: "Nettopris efter rabatt",
  __opcje_dodatkowe: "Ytterligare alternativ",
  __podsumowanie: "Sammanfattning",
  __dodatkowe_drzwi: "Ytterligare dörr",
  __montaz: "Montering",
  __transport: "Transport",
  __podziekowanie: "Tack så mycket för ditt intresse för vårt erbjudande.",
  __potwierdzenie_zamowienia: "Beställningsbekräftelse från kunden",
  __pieczatka: "Stämpel, datum och underskrift",
  __ulica_skrot: "gatuadress",
  __pozycja_szyny: "Skensposition",
  RIGHT: "Höger",
  LEFT: "Vänster",
  __tel: "Telefon nr.",
  __zmiana_statusu: "Ändra status för beställningsnummer",
  __z: "från",
  __na: "till",
  __brak_powiadomien: "Inga meddelanden",
  __zmiany: "Införda ändringar",
  __box: "Fältet",
  __zlozenie: "Montering av skärmtaket",
  __przedluzenie_tooltip:
    "Box-kategorins skärmtaketen har en skenförlängning på 220 cm. Avgiften tas ut endast om förlängningen överstiger 220 cm.",
  __zlozenie_tooltip:
    "Om alternativet skärmtaksmontering väljs, väljs skärmtaks vikning automatiskt.",
  __globalna_zmiana: "Global prisändring",
  __zmien: "Ändra",
  __procentowa_zmiana: "Förändringens procentuella värde",
  __powieksz: "Förstoring",
  __zmniejsz: "Minskning",
  __zaznacz_wszystkie: "Välj alla",
  __waluta_klienta: "Kundvaluta",
  __stawka_vat: "Momssats",
  __cena_brutto: "Bruttokundpris",
  EUR: "Euro",
  PLN: "Zloty",
  GBP: "Pund sterling",
  SEK: "Svenska kronor",
  NOK: "Norska kronor",
  USD: "Amerikanska dollar",
  CHF: "Schweiziska franc",
  CZK: "Tjeckiska korunan",
  HUF: "Forint",
  DKK: "Danska kronor",
  __cena_brutto_rabat: "Bruttopris efter nedsättning",
  __brak_wymaganych_danych: "Uppgifter som krävs saknas",
  __uzupelnij_brakujace_dane:
    "Fyll i de uppgifter som saknas för att spara formuläret.",
  __cena_nie_uwzglednia:
    "Priset inkluderar inte färjeöverfarter och andra särskilda avgifter.",
  __rzut_blad:
    "Skenförlängningen är för lång för att generera en grafisk bild av en bilaga. Välj en skenförlängning som inte överstiger 1000 cm.",
  __koszt_montazu: "Installationskostnader",
  __dodane_przez: "Läggs till av",
  __termin_tooltip:
    "Beställningen kommer att levereras under den vecka som inkluderar det valda datumet.",
  __eksport_dla_klienta: "Export för kunden",
  __eksport_dla_klienta_bucovers: "Export för Bucovers kund",
  __eksport_bucovers: "Export för Bucovers",
  __rodzaj_eksportu: "Välj exporttyp",
  __eksport_bucovers_opis:
    "Bucovers export innehåller omfattande information om erbjudandet för våra affärspartners.",
  __eksport_dla_klienta_opis:
    "Export för kunden har utvecklats med våra affärspartners kunder i åtanke.",
  __eksport_dla_klienta_bucovers_opis:
    "Bucovers kundexport innehåller ett dokument som utarbetats för Bucovers detaljhandelskunder.",
  __druga_wysokosc: "Ytterligare höjd",
  __dodatkowe_zdjecia: "Ytterligare render",
  __dodatkowe_z_drzwiami: "Ytterligare med dörrar",
  __dodatkowe_z_klapa: "Ytterligare med klaff",
  __rozbicie_ceny: "Prisfördelning",
  __cena_wariantu_dla_klienta_przed_rabatem:
    "Nettopriset för varianten för kunden före rabatten",
  __rabat_na_wariant_dla_klienta: "Rabatt för kundvarianten",
  __cena_wariantu_dla_klienta_po_rabacie:
    "Nettopriset för varianten för kunden efter rabatt",
  __cena_przedluzenia_szyn_dla_klienta_przed_rabatem:
    "Nettopris för järnvägsutbyggnad för kund före rabatt",
  __rabat_na_przedluzenie_szyn_dla_klienta:
    "Järnvägsförlängningsrabatt för kund",
  __cena_przedluzenia_szyn_dla_klienta_po_rabacie:
    "Nettopris för järnvägsförlängning för kund efter rabatt",
  __cena_kluczyka_blokady_dla_klienta_przed_rabatem:
    "Nettopris på låsnyckel för kund före rabatt",
  __rabat_na_kluczyk_blokady_dla_klienta: "Rabatt på låsnyckel för kund",
  __cena_kluczyka_blokady_dla_klienta_po_rabacie:
    "Nettopris på låsnyckel för kund efter rabatt",
  __cena_drzwi_dla_klienta_przed_rabatem:
    "Nettopris på dörren för kunden före rabatt",
  __rabat_na_drzwi_dla_klienta: "Dörrrabatt för kunden",
  __cena_drzwi_dla_klienta_po_rabacie:
    "Nettopris på dörren för kunden efter rabatt",
  __cena_transportu_dla_klienta_przed_rabatem:
    "Nettopris för transport för kunden före rabatt",
  __rabat_na_transport_dla_klienta: "Rabatt på transport för kunden",
  __cena_transportu_dla_klienta_po_rabacie:
    "Nettopris för transport för kund efter rabatt",
  __cena_montazu_dla_klienta_przed_rabatem:
    "Netto installationspris för kund före rabatt",
  __rabat_na_montaz_dla_klienta: "Rabatt på montering för kunden",
  __cena_montazu_dla_klienta_po_rabacie:
    "Netto installationspris för kund efter rabatt",
  __cena_zlozenia_zadaszenia_dla_klienta_przed_rabatem:
    "Nettopris för montering av taket för kunden före rabatten",
  __rabat_na_zlozenie_zadaszenia_dla_klienta:
    "Rabatt för montering av tak åt kunden",
  __cena_zlozenia_zadaszenia_dla_klienta_po_rabacie:
    "Nettopris för montering av taket för kunden efter rabatt",
  __cena_dla_klienta: "Nettopris för kund",
};
