import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, Store } from "../../../redux/store";
import {
  editPartner,
  getPartners,
} from "../../../redux/actions/partner-actions";
import {
  formatAddress,
  formatCountry,
  formatDiscount,
} from "../../../utils/formatter-utils";
import { getCountries } from "../../../redux/actions/country-actions";
import PartnersFilters from "./partners-filters/partners-filters";
import { Partner } from "../../../types/partner-types";
import PaginationTable from "../../../components/pagination-table/pagination-table";
import Section from "../../../components/section/section";
import Box from "@mui/material/Box";
import withPageLoader, {
  WithPageLoaderProps,
} from "../../../hoc/with-page-loader";
import CountryField from "../../../components/country-select/country-field";
import { Columns } from "../../../components/pagination-table/table-types";
import { ELLIPSIS_STYLES } from "../../../components/pagination-table/table-row/table-row";
import { Typography } from "@mui/material";
import MenuActions, {
  Action,
} from "../../../components/menu-actions/menu-actions";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import { useNavigate } from "react-router-dom";
import { Route } from "../../../constants/navigation-constants";
import ConfirmationDialog from "../../../components/confirmation-dialog/confirmation-dialog";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { useTranslation } from "react-i18next";
import Checkbox from "@mui/material/Checkbox";

interface PartnersListProps extends WithPageLoaderProps {}

const tableStyles = {
  minWidth: 900,
};

const PartnersList = ({ finishLoading }: PartnersListProps) => {
  const partnerReducer = useSelector((state: Store) => state.partner);
  const { loading, size, sort, filters } = partnerReducer;
  const [isActiveDialogOpen, setActiveDialogOpen] = useState(false);
  const [pickedId, setPickedId] = useState<number | undefined>(undefined);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    init();
  }, []);

  const isActive = filters.active;

  const init = async () => {
    await Promise.all([
      dispatch(getCountries()),
      dispatch(
        getPartners({ params: { size, page: 0, sort, filters }, reload: true })
      ),
    ]);
    finishLoading();
  };

  const onActiveToggle = async () => {
    if (pickedId !== undefined) {
      setActiveDialogOpen(false);
      await dispatch(editPartner({ id: pickedId, active: !isActive }));
      dispatch(
        getPartners({ params: { size, page: 0, sort, filters }, reload: true })
      );
      setPickedId(undefined);
    }
  };

  const onActiveClick = (id?: number) => {
    setActiveDialogOpen(true);
    setPickedId(id);
  };

  const onCancelClick = () => {
    setActiveDialogOpen(false);
    setPickedId(undefined);
  };

  const getPartnerName = () =>
    partnerReducer.content?.find((partner) => partner.id === pickedId)?.name;

  const renderDiscountCell = (partner: Partner) => (
    <Typography key={`${partner.id}-discount-cell`} sx={ELLIPSIS_STYLES}>
      {formatDiscount(partner.discount)}
    </Typography>
  );

  const renderAddressCell = (partner: Partner) => (
    <Typography key={`${partner.id}-address-cell`} sx={ELLIPSIS_STYLES}>
      {formatAddress(partner.address)}
    </Typography>
  );

  const renderCountryCell = (partner: Partner) => (
    <CountryField
      key={`${partner.id}-country-cell`}
      countryId={formatCountry(partner.address)}
    />
  );

  const renderFreeRailExtensionCell = (partner: Partner) => (
    <Checkbox
      key={`${partner.id}-free-rail-extension-cell`}
      checked={partner.freeRailExtension}
    />
  );

  const actions: Action[] = [
    {
      id: "edit",
      icon: <EditIcon color="primary" />,
      text: t("edytuj"),
      onClick: (id) => {
        navigate(`${Route.PartnerEdit}/${id}`);
      },
    },
    {
      id: "archive",
      icon: isActive ? (
        <ArchiveIcon color="primary" />
      ) : (
        <UnarchiveIcon color="primary" />
      ),
      text: isActive ? t("dezaktywuj") : t("aktywuj"),
      onClick: onActiveClick,
    },
  ];

  const renderActionsCell = (partner: Partner) => (
    <MenuActions
      key={`${partner.id}-actions-cell`}
      actions={actions}
      entityId={partner.id}
    />
  );

  const columns: Columns<Partner> = [
    { id: "name", accessor: "name", flexGrow: 3, name: t("nazwa") },
    { id: "email", accessor: "email", flexGrow: 4, name: t("mail") },
    {
      id: "address",
      flexGrow: 5,
      cellRenderer: renderAddressCell,
      name: t("adres"),
    },

    {
      id: "country",
      flexGrow: 2,
      cellRenderer: renderCountryCell,
      name: t("kraj"),
    },
    {
      id: "free-rail-extention",
      cellRenderer: renderFreeRailExtensionCell,
      name: t("darmowe_przedluzenie_szyn"),
      flexGrow: 2,
    },
    { id: "discount", cellRenderer: renderDiscountCell, name: t("znizka") },
    {
      id: "actions",
      cellRenderer: renderActionsCell,
      name: "",
      justify: "end",
    },
  ];

  const table = (
    <PaginationTable<Partner>
      withPagination={true}
      loading={loading}
      fetcherAction={getPartners}
      reducerName="partner"
      columns={columns}
    />
  );

  return (
    <Box>
      <ConfirmationDialog
        open={isActiveDialogOpen}
        title={t("potwierdz_operacje")}
        primaryText={t("anuluj")}
        onPrimary={onCancelClick}
        secondaryText={isActive ? t("dezaktywuj") : t("aktywuj")}
        onSecondary={onActiveToggle}
      >
        <Typography>
          {isActive
            ? t("dezaktywacja_partnera_pytanie")
            : t("aktywacja_partnera_pytanie")}{" "}
          <strong>({getPartnerName()})</strong>
        </Typography>
      </ConfirmationDialog>
      <Section
        title={t("partnerzy")}
        actions={<PartnersFilters />}
        sx={tableStyles}
      >
        {table}
      </Section>
    </Box>
  );
};

export default withPageLoader(PartnersList);
