import { jsPDF } from "jspdf";
import { Order } from "../../types/order-types";
import { Theme } from "@mui/material";
import { TFunction } from "i18next";
import { robotoRegular } from "../../assets/fonts/roboto-regular";
import { robotoBold } from "../../assets/fonts/roboto-bold";
import { pdfTopLogo } from "../../assets/images/base64/pdf-top-logo";
import {
  formatAddress,
  formatClientAddress,
  formatDate,
  formatDiscount,
  formatPrice,
} from "../../utils/formatter-utils";
import { PhotoType } from "../../components/photo-preview/photo-preview";
import { DoorEnum } from "../../constants/door-constants";
import { ClapEnum } from "../../constants/claps-constants";
import { pdfBottomLogo } from "../../assets/images/base64/pdf-bottom-logo";
import { CANVAS_ID } from "../../pages/orders-page/order-edit/order-form/technical-projection/technical-projection.constants";
import { getRailAndWheelsWidth } from "../../pages/orders-page/order-edit/order-form/technical-projection/technical-projection.utils";
import { CURRENCIES } from "../../types/price-types";

const MAX_WIDTH = 210;
const MARGIN = 18;
const DEFAULT_FONT_SIZE = 8;
const HALF_WIDTH = (MAX_WIDTH - 2 * MARGIN) / 2;

export const generatePdf = async (
  order: Order,
  theme: Theme,
  t: TFunction,
  clientCountryName: string,
  partnerCountryName: string,
  clientExport: boolean,
  bucoversClientExport: boolean
) => {
  const doc = new jsPDF();
  const rowHeight = 7;
  const gap = 7;
  const partnerPriceRows =
    clientExport || bucoversClientExport ? -1 : getPriceRows(4, order);
  const clientPriceRows =
    order.client && (clientExport || bucoversClientExport)
      ? getPriceRows(5, order)
      : -1;
  const adjustments = { additionalLines: 0 };
  const top = clientExport ? 120 : 150;
  const canvas = document.getElementById(CANVAS_ID) as HTMLCanvasElement;
  if (!canvas) return;

  const { width, height } = canvas.getBoundingClientRect();
  const displayedWidth = MAX_WIDTH * 0.7;
  const displayedHeight = (height * displayedWidth) / width;
  const areDetailedPrices =
    order.payment.clientPrice === undefined ||
    order.payment.clientDiscount === undefined;

  const renderPricesForClient = (
    doc: jsPDF,
    theme: Theme,
    t: TFunction,
    order: Order,
    top: number
  ) => {
    areDetailedPrices
      ? renderClientDetailedPrices(doc, theme, t, order, 65)
      : renderClientPrices(doc, theme, t, order, top);
  };

  configureFonts(doc);
  renderHeader(doc, theme, t, clientExport);
  renderTitle(doc, theme, t, order, clientExport);
  renderCreationDate(doc, t, order, clientExport);
  !clientExport && renderPreparedBy(doc, t, order);
  renderDeadline(doc, t, clientExport);
  !clientExport && renderProvider(doc, theme, t);
  !bucoversClientExport &&
    renderPartner(doc, t, order, partnerCountryName, clientExport);
  order.client &&
    renderClient(
      doc,
      t,
      order,
      clientCountryName,
      clientExport,
      bucoversClientExport
    );
  await renderVariant(doc, theme, t, order, clientExport, adjustments);
  renderTechnicalProjection(
    doc,
    order,
    clientExport,
    theme,
    canvas,
    top,
    displayedHeight,
    displayedWidth,
    adjustments.additionalLines
  );
  renderComments(
    doc,
    theme,
    t,
    order,
    clientExport,
    top + displayedHeight * 1.02 + adjustments.additionalLines * 5
  );
  doc.addPage();
  await renderDoors(doc, theme, t, order);

  doc.setFontSize(DEFAULT_FONT_SIZE);
  !clientExport &&
    !bucoversClientExport &&
    renderPartnerPrices(doc, theme, t, order);
  order.client &&
    (clientExport || bucoversClientExport) &&
    renderPricesForClient(
      doc,
      theme,
      t,
      order,
      65 + MARGIN + rowHeight * partnerPriceRows + gap
    );
  renderThanks(
    doc,
    t,
    theme,
    65 + MARGIN + rowHeight * (partnerPriceRows + clientPriceRows) + gap * 2 + 5
  );
  renderFooter(
    doc,
    theme,
    t,
    65 +
      MARGIN +
      rowHeight * (partnerPriceRows + clientPriceRows) +
      gap * 3 +
      5,
    clientExport
  );
  downloadPdf(doc, order, t);
};

const getPriceRows = (base: number, order: Order) => {
  let priceRows = base;
  if (order.railLength > 0) {
    priceRows++;
  }
  if (isAnythingOnFront(order)) {
    priceRows++;
  }
  if (isAnythingOnBack(order)) {
    priceRows++;
  }
  if (order.withMontage) {
    priceRows++;
  }
  if (order.withTransport) {
    priceRows++;
  }
  if (order.box !== undefined && !order.box) {
    priceRows++;
  }
  if (order.brakeKey) {
    priceRows++;
  }

  return priceRows;
};

const configureFonts = (doc: jsPDF) => {
  doc.addFileToVFS("Roboto-Regular.ttf", robotoRegular);
  doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
  doc.addFileToVFS("Roboto-Bold.ttf", robotoBold);
  doc.addFont("Roboto-Bold.ttf", "Roboto", "bold");
  doc.setFont("Roboto");
};

const renderHeader = (
  doc: jsPDF,
  theme: Theme,
  t: TFunction,
  hideBucovers: boolean
) => {
  const top = 20;
  doc.setDrawColor(theme.palette.primary.dark);
  doc.setLineWidth(0.4);
  doc.line(MARGIN, MARGIN, MAX_WIDTH - MARGIN, MARGIN);

  if (hideBucovers) return;

  doc.setFontSize(13);
  doc.setTextColor(theme.palette.primary.dark);
  doc.text(t("__export_header"), MARGIN, top + 5);

  doc.setTextColor(theme.palette.common.black);
  doc.setFontSize(DEFAULT_FONT_SIZE);
  doc.text(
    "(+48) 605 212 213 , (+48) 693 400 087, (+48) 693 400 085",
    MARGIN,
    top + 11
  );

  doc.addImage(pdfTopLogo, "png", 140, top, 40, 20);
};

const renderTitle = (
  doc: jsPDF,
  theme: Theme,
  t: TFunction,
  order: Order,
  hideBucovers: boolean
) => {
  const top = hideBucovers ? 25 : 42;
  doc.setTextColor(theme.palette.primary.dark);
  doc.setFontSize(13);
  setBoldFont(doc);
  doc.text(`${t("OFFER")} ${order.id}`, MARGIN, top);
  setDefaultFont(doc, theme);
};

const renderCreationDate = (
  doc: jsPDF,
  t: TFunction,
  order: Order,
  hideBucovers: boolean
) => {
  const top = hideBucovers ? 35 : 52;
  setBoldFont(doc);
  doc.text(t("data_dodania"), MARGIN, top);
  setRegularFont(doc);
  doc.text(formatDate(order.createdDate), MARGIN, top + 5);
};

const renderPreparedBy = (doc: jsPDF, t: TFunction, order: Order) => {
  const top = 52;
  setBoldFont(doc);
  doc.text(t("__oferta_przygotowana_przez"), MARGIN + 45, top);
  setRegularFont(doc);
  const phone = order.createdBy.phone
    ? `(${order.createdBy.phone.prefix}) ${order.createdBy.phone.number}`
    : "";
  const createdBy = `${order.createdBy.firstname} ${
    order.createdBy.lastname
  }, ${t("__tel")} ${phone}`;
  doc.text(createdBy, MARGIN + 45, top + 5);
};

const renderDeadline = (doc: jsPDF, t: TFunction, clientExport: boolean) => {
  const left = clientExport ? MARGIN : MARGIN + 120;
  const top = clientExport ? 50 : 52;
  setBoldFont(doc);
  doc.text(t("__oferta_wazna"), left, top);
  setRegularFont(doc);
  const text = `30 ${t("__dni")}`;
  doc.text(text, left, top + 5);
};

const renderProvider = (doc: jsPDF, theme: Theme, t: TFunction) => {
  const top = 65;
  doc.setDrawColor(theme.palette.common.black);
  doc.setLineWidth(0.3);
  setBoldFont(doc);
  doc.text(t("__dostawca"), MARGIN, top);
  setRegularFont(doc);
  doc.text("P. W. Bukowscy Sp. z o.o.", MARGIN, top + 5);
  doc.text("Wiecanowo 3a", MARGIN, top + 10);
  doc.text("88-300 Mogilno", MARGIN, top + 15);
  setBoldFont(doc);
  doc.text("www.bucovers.pl", MARGIN, top + 20);
  const textWidth1 = doc.getTextWidth("www.bucovers.pl");
  doc.line(MARGIN, top + 20.4, MARGIN + textWidth1, top + 20.4);
  setRegularFont(doc);
  doc.text("bucovers@bucovers.pl", MARGIN, top + 25);
  const textWidth2 = doc.getTextWidth("bucovers@bucovers.pl");
  doc.line(MARGIN, top + 25.4, MARGIN + textWidth2, top + 25.4);
};

const renderClient = (
  doc: jsPDF,
  t: TFunction,
  order: Order,
  countryName: string,
  clientExport: boolean,
  bucoversClientExport: boolean
) => {
  const { client } = order;
  if (client) {
    const top = clientExport ? 35 : 65;
    const left = bucoversClientExport ? MARGIN + 45 : MARGIN + 120;
    setBoldFont(doc);
    doc.text(t("__odbiorca"), left, top);
    setRegularFont(doc);
    doc.text(client.name, left, top + 5);
    doc.text(formatClientAddress(client.address), left, top + 10);
    doc.text(countryName, left, top + 15);
    if (client.email) {
      doc.text(client.email, left, top + 20);
      if (client.phone.number) {
        doc.text(
          `(${client.phone.prefix}) ${client.phone.number}`,
          left,
          top + 25
        );
      }
    } else {
      if (client.phone.number) {
        doc.text(
          `(${client.phone.prefix}) ${client.phone.number}`,
          left,
          top + 20
        );
      }
    }
  }
};

const renderPartner = (
  doc: jsPDF,
  t: TFunction,
  order: Order,
  countryName: string,
  hideBucovers: boolean
) => {
  const { partner } = order;
  if (partner) {
    const top = hideBucovers ? 35 : 65;
    const left = MARGIN + 45;
    setBoldFont(doc);
    doc.text(t("partner"), left, top);
    setRegularFont(doc);
    doc.text(partner.name, left, top + 5);
    doc.text(formatAddress(partner.address), left, top + 10);
    doc.text(countryName, left, top + 15);
    doc.text(partner.email, left, top + 20);
    doc.text(
      `(${partner.phone.prefix}) ${partner.phone.number}`,
      left,
      top + 25
    );
  }
};

const addImageAsync = async (src: string) => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = reject;
  });
};

const renderVariant = async (
  doc: jsPDF,
  theme: Theme,
  t: TFunction,
  order: Order,
  hideBucovers: boolean,
  adjustments: { additionalLines: number }
) => {
  const top = hideBucovers ? 65 : 95;
  const { length, widthExt, height, widthInt, modulesCount, secondaryHeight } =
    order.variant;

  const heightText = secondaryHeight
    ? `${height} / ${secondaryHeight}`
    : height;

  const MAX_LINE_WIDTH = 60;

  const texts = [
    { label: addSemicolon(t("__model_zadaszanie")), value: order.model.label },
    {
      label: addSemicolon(t("__oznaczenie_produkcji")),
      value: `${widthInt * 10} - ${modulesCount} modules`,
    },
    { label: addSemicolon(t("__wypelnienie")), value: order.filling.label },
    { label: addSemicolon(t("__kolor")), value: order.color.label },
    {
      label: addSemicolon(t("__dlugosc_zadaszenia")),
      value: length.toString(),
    },
    {
      label: addSemicolon(t("__wysokosc_zadaszenia")),
      value: heightText.toString(),
    },
    {
      label: addSemicolon(t("__rozstaw_wewnetrzny")),
      value: widthInt.toString(),
    },
    {
      label: addSemicolon(t("__rozstaw_zewnetrzny")),
      value: widthExt.toString(),
    },
    {
      label: addSemicolon(t("__szerokosc_szyn")),
      value: getRailAndWheelsWidth(
        order.variant,
        order.railLength,
        order.model.railCount
      ).railWidth.toString(),
    },
    {
      label: addSemicolon(t("__przedluzenie_szyn")),
      value: order.railLength.toString(),
    },
    { label: addSemicolon(t("__rodzaj_podloza")), value: order.floorBase },
  ];

  let y = top;

  texts.forEach((text, index) => {
    const textWidth = doc.getTextWidth(text.value);
    const linesNeeded = Math.ceil(textWidth / MAX_LINE_WIDTH);
    adjustments.additionalLines += linesNeeded - 1;
    const textHeight = 5 * linesNeeded;

    if (index % 2 === 0) {
      doc.setDrawColor(theme.palette.grey.A200);
      doc.setFillColor(theme.palette.grey.A200);
      doc.rect(MARGIN, y, HALF_WIDTH, textHeight, "F");
    }

    doc.text(text.label, MARGIN + 1, y + textHeight / 2, {
      baseline: "middle",
    });
    doc.text(text.value, MARGIN + 55, y + textHeight / 4 + 1, {
      align: "center",
      baseline: "middle",
      maxWidth: MAX_LINE_WIDTH,
    });

    y += textHeight;
  });

  doc.setDrawColor(theme.palette.grey["300"]);
  doc.setFillColor(theme.palette.common.white);
  doc.rect(
    MARGIN + HALF_WIDTH,
    top,
    HALF_WIDTH,
    55 + adjustments.additionalLines * 5
  );

  if (order.model.defaultPhoto?.id) {
    const imgUrl = `${process.env.REACT_APP_API_PREFIX}/storage/photo/${order.model.defaultPhoto.id}`;
    const img = await addImageAsync(imgUrl);
    doc.addImage(
      img,
      "png",
      MARGIN + HALF_WIDTH + 2.5,
      top + 1 + (adjustments.additionalLines * 5) / 2,
      HALF_WIDTH - 5,
      55 - 2
    );
  }
};

const renderTechnicalProjection = (
  doc: jsPDF,
  order: Order,
  hideBucovers: boolean,
  theme: Theme,
  canvas: HTMLCanvasElement,
  top: number,
  displayedHeight: number,
  displayedWidth: number,
  additionalLines: number
) => {
  const adjustedTop = top + 5 * additionalLines;
  doc.setDrawColor(theme.palette.grey["300"]);
  doc.setFillColor(theme.palette.common.white);
  doc.rect(MARGIN, adjustedTop, 2 * HALF_WIDTH, displayedHeight * 1.02);

  if (canvas) {
    doc.addImage(
      canvas.toDataURL("image/png"),
      "png",
      (MAX_WIDTH - displayedWidth) / 2,
      adjustedTop - displayedHeight * 0.02,
      displayedWidth,
      displayedHeight
    );
  }
};

const renderDoors = async (
  doc: jsPDF,
  theme: Theme,
  t: TFunction,
  order: Order
) => {
  const top = MARGIN;
  const height = 5;
  const frontPhoto = getFrontPhotoType(order);
  const backPhoto = getBackPhotoType(order);
  if (order.model.defaultPhoto?.id) {
    const frontImageUrl = `${process.env.REACT_APP_API_PREFIX}/storage/photo/${order.model[frontPhoto]?.id}`;
    const frontImg = await addImageAsync(frontImageUrl);
    doc.addImage(frontImg, "png", MARGIN + 18, top + height + 1, 48, 28);

    const backImageUrl = `${process.env.REACT_APP_API_PREFIX}/storage/photo/${order.model[backPhoto]?.id}`;
    const backImg = await addImageAsync(backImageUrl);
    doc.addImage(
      backImg,
      "png",
      MARGIN + HALF_WIDTH + 18,
      top + height + 1,
      48,
      28
    );
  }

  doc.setDrawColor(theme.palette.grey["300"]);
  doc.rect(MARGIN, top, HALF_WIDTH, height);
  doc.rect(MARGIN + HALF_WIDTH, top, HALF_WIDTH, height);
  doc.rect(MARGIN, top + height, HALF_WIDTH, 30);
  doc.rect(MARGIN + HALF_WIDTH, top + height, HALF_WIDTH, 30);
  setBoldFont(doc);
  doc.text(
    t("__sciana_przednia").toUpperCase(),
    MARGIN + HALF_WIDTH / 2,
    top + 3.5,
    { align: "center" }
  );
  doc.text(
    t("__sciana_tylnia").toUpperCase(),
    MARGIN + (HALF_WIDTH * 3) / 2,
    top + 3.5,
    { align: "center" }
  );
  setRegularFont(doc);

  doc.setDrawColor(theme.palette.grey.A200);
  doc.setFillColor(theme.palette.grey.A200);
  doc.rect(MARGIN, top + 35, MAX_WIDTH - 2 * MARGIN, 5, "F");
  doc.rect(MARGIN, top + 45, MAX_WIDTH - 2 * MARGIN, 5, "F");
  doc.text(
    addSemicolon(t("__podwyzszenie_sciany")),
    MARGIN + 1,
    top + 35 + 3.5
  );
  doc.text(addSemicolon(t("__drzwi")), MARGIN + 1, top + 40 + 3.5);
  doc.text(addSemicolon(t("__klapa")), MARGIN + 1, top + 45 + 3.5);

  doc.text(
    addSemicolon(t("__podwyzszenie_sciany")),
    MARGIN + HALF_WIDTH + 1,
    top + 35 + 3.5
  );
  doc.text(addSemicolon(t("__drzwi")), MARGIN + HALF_WIDTH + 1, top + 40 + 3.5);
  doc.text(addSemicolon(t("__klapa")), MARGIN + HALF_WIDTH + 1, top + 45 + 3.5);

  const offset = 65;

  doc.text(order.wallIncrease.toString(), MARGIN + offset, top + 35 + 3.5, {
    align: "center",
  });
  doc.text(
    order.doorType.includes(DoorEnum.FrontMain)
      ? t("__tak").toLowerCase()
      : "x",
    MARGIN + offset,
    top + 40 + 3.5,
    {
      align: "center",
    }
  );
  doc.text(
    order.clapType.includes(ClapEnum.Front) ? t("__tak").toLowerCase() : "x",
    MARGIN + offset,
    top + 45 + 3.5,
    {
      align: "center",
    }
  );

  doc.text(
    order.wallIncrease.toString(),
    MARGIN + HALF_WIDTH + 1 + offset,
    top + 35 + 3.5,
    { align: "center" }
  );
  doc.text(
    order.doorType.includes(DoorEnum.BackMain) ? t("__tak").toLowerCase() : "x",
    MARGIN + HALF_WIDTH + 1 + offset,
    top + 40 + 3.5,
    {
      align: "center",
    }
  );
  doc.text(
    order.clapType.includes(ClapEnum.Back) ? t("__tak").toLowerCase() : "x",
    MARGIN + HALF_WIDTH + 1 + offset,
    top + 45 + 3.5,
    {
      align: "center",
    }
  );
};

const renderComments = (
  doc: jsPDF,
  theme: Theme,
  t: TFunction,
  order: Order,
  hideBucovers: boolean,
  top: number
) => {
  doc.setDrawColor(theme.palette.grey["300"]);
  doc.rect(MARGIN, top, 25, 5);
  doc.rect(MARGIN + 25, top, MAX_WIDTH - 2 * MARGIN - 25, 25);
  doc.text(addSemicolon(t("__komentarze")), MARGIN + 1, top + 3.5);
};

const renderPartnerPrices = (
  doc: jsPDF,
  theme: Theme,
  t: TFunction,
  order: Order
) => {
  const top = 60;
  const rowHeight = 7;
  const textOffset = 4.5;
  const { frontPrice, frontDiscount, backPrice, backDiscount } =
    getFrontDoorPrice(order);
  const currencySymbol = CURRENCIES.find(
    (c) => c.code === order.payment.clientCurrency
  )?.symbol;

  const options = [];
  if (order.railLength > 0) {
    const row = {
      label: t("__przedluzenie_szyn"),
      price: addCurrency(
        formatPrice(order.payment.railExtensionPrice),
        currencySymbol
      ),
      discount: formatDiscount(
        order.payment?.partnerDiscount ?? order.partner.discount
      ),
      finalPrice: addCurrency(
        formatPrice(
          order.payment.railExtensionPrice -
            order.payment.railExtensionPriceDiscount
        ),
        currencySymbol
      ),
    };
    options.push(row);
  }
  if (isAnythingOnFront(order)) {
    const row = {
      label: `${t("__dodatkowe_drzwi")} - ${t(
        "__sciana_przednia"
      ).toLowerCase()}`,
      price: addCurrency(formatPrice(frontPrice), currencySymbol),
      discount: formatDiscount(
        order.payment?.partnerDiscount ?? order.partner.discount
      ),
      finalPrice: addCurrency(
        formatPrice(frontPrice - frontDiscount),
        currencySymbol
      ),
    };
    options.push(row);
  }
  if (isAnythingOnBack(order)) {
    const row = {
      label: `${t("__dodatkowe_drzwi")} - ${t(
        "__sciana_tylnia"
      ).toLowerCase()}`,
      price: addCurrency(formatPrice(backPrice), currencySymbol),
      discount: formatDiscount(
        order.payment?.partnerDiscount ?? order.partner.discount
      ),
      finalPrice: addCurrency(
        formatPrice(backPrice - backDiscount),
        currencySymbol
      ),
    };
    options.push(row);
  }
  if (order.brakeKey) {
    const row = {
      label: t("__kluczyk_blokady"),
      price: addCurrency(formatPrice(order.payment.brakePrice), currencySymbol),
      discount: formatDiscount(
        order.payment?.partnerDiscount ?? order.partner.discount
      ),
      finalPrice: addCurrency(
        formatPrice(
          order.payment.brakePrice - order.payment.brakePriceDiscount
        ),
        currencySymbol
      ),
    };
    options.push(row);
  }
  if (order.withMontage) {
    const row = {
      label: t("__montaz"),
      price: addCurrency(
        formatPrice(order.payment.montagePrice),
        currencySymbol
      ),
      discount: formatDiscount(0),
      finalPrice: addCurrency(
        formatPrice(order.payment.montagePrice),
        currencySymbol
      ),
    };
    options.push(row);
  }
  if (order.withTransport) {
    const row = {
      label: t("__transport"),
      price: addCurrency(
        formatPrice(order.payment.transportPrice),
        currencySymbol
      ),
      discount: formatDiscount(0),
      finalPrice: addCurrency(
        formatPrice(order.payment.transportPrice),
        currencySymbol
      ),
    };
    options.push(row);
  }
  if (order.box !== undefined && !order.box) {
    const row = {
      label: t("__zlozenie"),
      price: addCurrency(
        formatPrice(order.payment.boxMontagePrice),
        currencySymbol
      ),
      discount: formatDiscount(0),
      finalPrice: addCurrency(
        formatPrice(order.payment.boxMontagePrice),
        currencySymbol
      ),
    };
    options.push(row);
  }

  for (let i = 0; i < 3 + options.length; i++) {
    doc.setDrawColor(theme.palette.grey["300"]);
    doc.rect(MARGIN, top + MARGIN + i * rowHeight, HALF_WIDTH, rowHeight);
    doc.rect(
      MARGIN + HALF_WIDTH,
      top + MARGIN + i * rowHeight,
      HALF_WIDTH / 4,
      rowHeight
    );
    doc.rect(
      MARGIN + HALF_WIDTH * 1.25,
      top + MARGIN + i * rowHeight,
      HALF_WIDTH / 4,
      rowHeight
    );
    doc.rect(
      MARGIN + HALF_WIDTH * 1.5,
      top + MARGIN + i * rowHeight,
      HALF_WIDTH / 2,
      rowHeight
    );
  }
  doc.rect(
    MARGIN,
    top + MARGIN + (3 + options.length) * rowHeight,
    HALF_WIDTH * 1.5,
    rowHeight
  );
  doc.rect(
    MARGIN + HALF_WIDTH * 1.5,
    top + MARGIN + (3 + options.length) * rowHeight,
    HALF_WIDTH / 2,
    rowHeight
  );

  setBoldFont(doc);
  doc.setTextColor(theme.palette.primary.dark);
  doc.text(
    t("__specyfikacja_partner").toUpperCase(),
    MARGIN + HALF_WIDTH / 2,
    top + MARGIN + textOffset,
    {
      align: "center",
    }
  );
  doc.text(
    t("__cena_netto"),
    MARGIN + HALF_WIDTH + HALF_WIDTH / 8,
    top + MARGIN + textOffset,
    {
      align: "center",
    }
  );
  doc.text(
    t("__rabat"),
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 3) / 8,
    top + MARGIN + textOffset,
    {
      align: "center",
    }
  );
  doc.text(
    t("__cena_netto_po_rabacie"),
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 3) / 4,
    top + MARGIN + textOffset,
    {
      align: "center",
    }
  );
  doc.text(
    t("__opcje_dodatkowe").toUpperCase(),
    MARGIN + HALF_WIDTH / 2,
    top + MARGIN + textOffset + rowHeight * 2,
    {
      align: "center",
    }
  );
  doc.text(
    t("__cena_netto"),
    MARGIN + HALF_WIDTH + HALF_WIDTH / 8,
    top + MARGIN + textOffset + rowHeight * 2,
    {
      align: "center",
    }
  );
  doc.text(
    t("__rabat"),
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 3) / 8,
    top + MARGIN + textOffset + rowHeight * 2,
    {
      align: "center",
    }
  );
  doc.text(
    t("__cena_netto_po_rabacie"),
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 3) / 4,
    top + MARGIN + textOffset + rowHeight * 2,
    {
      align: "center",
    }
  );
  doc.text(
    t("__podsumowanie"),
    (MARGIN + HALF_WIDTH + (HALF_WIDTH * 3) / 4) / 2,
    top + MARGIN + textOffset + rowHeight * (3 + options.length),
    {
      align: "center",
    }
  );
  doc.text(
    addCurrency(formatPrice(order.payment.partnerPrice), currencySymbol),
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 3) / 4,
    top + MARGIN + textOffset + rowHeight * (3 + options.length),
    {
      align: "center",
    }
  );

  setDefaultFont(doc, theme);
  doc.text(
    `${t("__model")} ${order.model.label}`,
    MARGIN + 1,
    top + MARGIN + textOffset + rowHeight
  );
  doc.text(
    addCurrency(formatPrice(order.payment.variantPrice), currencySymbol),
    MARGIN + HALF_WIDTH + HALF_WIDTH / 8,
    top + MARGIN + textOffset + rowHeight,
    {
      align: "center",
    }
  );
  doc.text(
    formatDiscount(order.payment?.partnerDiscount ?? order.partner.discount),
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 3) / 8,
    top + MARGIN + textOffset + rowHeight,
    {
      align: "center",
    }
  );
  doc.text(
    addCurrency(
      formatPrice(
        order.payment.variantPrice - order.payment.variantPriceDiscount
      ),
      currencySymbol
    ),
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 3) / 4,
    top + MARGIN + textOffset + rowHeight,
    {
      align: "center",
    }
  );

  options.forEach((row, index) =>
    renderPartnerPriceRow(doc, t, row, index, MARGIN + top)
  );
};

const getPriceWithDiscount = (
  detailedPrice: number | undefined,
  detailedDiscount: number | undefined
) => {
  const price = detailedPrice || 0;
  const discount = detailedDiscount || 0;
  const final = price * (1 - discount);
  return { price, discount, final };
};

const renderClientDetailedPrices = (
  doc: jsPDF,
  theme: Theme,
  t: TFunction,
  order: Order,
  top: number
) => {
  const rowHeight = 7;
  const textOffset = 4.5;
  const { frontPrice, backPrice } = getFrontDoorDetailedPrice(order);
  const currencySymbol = CURRENCIES.find(
    (c) => c.code === order.payment.clientCurrency
  )?.symbol;

  const options = [];
  if (order.railLength > 0) {
    const { price, discount, final } = getPriceWithDiscount(
      order.payment.railExtensionClientPrice,
      order.payment.railExtensionClientPriceDiscount
    );
    const row = {
      label: t("__przedluzenie_szyn"),
      price: addCurrency(formatPrice(price), currencySymbol),
      discount: formatDiscount(discount),
      finalPrice: addCurrency(formatPrice(final), currencySymbol),
    };
    options.push(row);
  }
  if (isAnythingOnFront(order)) {
    const { discount } = getPriceWithDiscount(
      order.payment.doorClientPrice,
      order.payment.doorClientPriceDiscount
    );
    const row = {
      label: `${t("__dodatkowe_drzwi")} - ${t(
        "__sciana_przednia"
      ).toLowerCase()}`,
      price: addCurrency(formatPrice(frontPrice), currencySymbol),
      discount: formatDiscount(discount),
      finalPrice: addCurrency(
        formatPrice(
          frontPrice * (1 - (order.payment.doorClientPriceDiscount || 0))
        ),
        currencySymbol
      ),
    };
    options.push(row);
  }
  if (isAnythingOnBack(order)) {
    const { discount } = getPriceWithDiscount(
      order.payment.doorClientPrice,
      order.payment.doorClientPriceDiscount
    );
    const row = {
      label: `${t("__dodatkowe_drzwi")} - ${t(
        "__sciana_tylnia"
      ).toLowerCase()}`,
      price: addCurrency(formatPrice(backPrice), currencySymbol),
      discount: formatDiscount(discount),
      finalPrice: addCurrency(
        formatPrice(
          backPrice * (1 - (order.payment.doorClientPriceDiscount || 0))
        ),
        currencySymbol
      ),
    };
    options.push(row);
  }
  if (order.brakeKey) {
    const { price, discount, final } = getPriceWithDiscount(
      order.payment.brakeClientPrice,
      order.payment.brakeClientPriceDiscount
    );
    const row = {
      label: t("__kluczyk_blokady"),
      price: addCurrency(formatPrice(price), currencySymbol),
      discount: formatDiscount(discount),
      finalPrice: addCurrency(formatPrice(final), currencySymbol),
    };
    options.push(row);
  }
  if (order.withMontage) {
    const { price, discount, final } = getPriceWithDiscount(
      order.payment.montageClientPrice,
      order.payment.montageClientPriceDiscount
    );
    const row = {
      label: t("__montaz"),
      price: addCurrency(formatPrice(price), currencySymbol),
      discount: formatDiscount(discount),
      finalPrice: addCurrency(formatPrice(final), currencySymbol),
    };
    options.push(row);
  }
  if (order.withTransport) {
    const { price, discount, final } = getPriceWithDiscount(
      order.payment.transportClientPrice,
      order.payment.transportClientPriceDiscount
    );
    const row = {
      label: t("__transport"),
      price: addCurrency(formatPrice(price), currencySymbol),
      discount: formatDiscount(discount),
      finalPrice: addCurrency(formatPrice(final), currencySymbol),
    };
    options.push(row);
  }
  if (order.box !== undefined && !order.box) {
    const { price, discount, final } = getPriceWithDiscount(
      order.payment.boxMontageClientPrice,
      order.payment.boxMontageClientPriceDiscount
    );
    const row = {
      label: t("__zlozenie"),
      price: addCurrency(formatPrice(price), currencySymbol),
      discount: formatDiscount(discount),
      finalPrice: addCurrency(formatPrice(final), currencySymbol),
    };
    options.push(row);
  }

  for (let i = 0; i < 3 + options.length; i++) {
    doc.setDrawColor(theme.palette.grey["300"]);
    doc.rect(MARGIN, top + MARGIN + i * rowHeight, HALF_WIDTH, rowHeight);
    doc.rect(
      MARGIN + HALF_WIDTH,
      top + MARGIN + i * rowHeight,
      HALF_WIDTH / 4,
      rowHeight
    );
    doc.rect(
      MARGIN + HALF_WIDTH * 1.25,
      top + MARGIN + i * rowHeight,
      HALF_WIDTH / 4,
      rowHeight
    );
    doc.rect(
      MARGIN + HALF_WIDTH * 1.5,
      top + MARGIN + i * rowHeight,
      HALF_WIDTH / 2,
      rowHeight
    );
  }
  doc.rect(
    MARGIN,
    top + MARGIN + (3 + options.length) * rowHeight,
    HALF_WIDTH * 1.5,
    rowHeight
  );
  doc.rect(
    MARGIN + HALF_WIDTH * 1.5,
    top + MARGIN + (3 + options.length) * rowHeight,
    HALF_WIDTH / 2,
    rowHeight
  );

  doc.rect(
    MARGIN + HALF_WIDTH * 0.75,
    top + MARGIN + (3 + options.length) * rowHeight,
    HALF_WIDTH / 2,
    rowHeight
  );

  doc.rect(
    MARGIN + HALF_WIDTH * 1.25,
    top + MARGIN + (3 + options.length) * rowHeight,
    HALF_WIDTH / 4,
    rowHeight
  );
  doc.rect(
    MARGIN + HALF_WIDTH * 1.5,
    top + MARGIN + (3 + options.length) * rowHeight,
    HALF_WIDTH / 2,
    rowHeight
  );

  doc.rect(
    MARGIN + HALF_WIDTH * 1.5,
    top + MARGIN + (3 + options.length + 1) * rowHeight,
    HALF_WIDTH / 2,
    rowHeight
  );

  doc.rect(
    MARGIN + HALF_WIDTH * 0.75,
    top + MARGIN + (3 + options.length + 1) * rowHeight,
    HALF_WIDTH / 2,
    rowHeight
  );

  doc.rect(
    MARGIN + HALF_WIDTH * 1.25,
    top + MARGIN + (3 + options.length + 1) * rowHeight,
    HALF_WIDTH / 4,
    rowHeight
  );
  doc.rect(
    MARGIN + HALF_WIDTH * 1.5,
    top + MARGIN + (3 + options.length + 1) * rowHeight,
    HALF_WIDTH / 2,
    rowHeight
  );

  setBoldFont(doc);
  doc.setTextColor(theme.palette.primary.dark);
  doc.text(
    t("__specyfikacja_partner").toUpperCase(),
    MARGIN + HALF_WIDTH / 2,
    top + MARGIN + textOffset,
    {
      align: "center",
    }
  );
  doc.text(
    t("__cena_netto"),
    MARGIN + HALF_WIDTH + HALF_WIDTH / 8,
    top + MARGIN + textOffset,
    {
      align: "center",
    }
  );
  doc.text(
    t("__rabat"),
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 3) / 8,
    top + MARGIN + textOffset,
    {
      align: "center",
    }
  );
  doc.text(
    t("__cena_netto_po_rabacie"),
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 3) / 4,
    top + MARGIN + textOffset,
    {
      align: "center",
    }
  );
  doc.text(
    t("__opcje_dodatkowe").toUpperCase(),
    MARGIN + HALF_WIDTH / 2,
    top + MARGIN + textOffset + rowHeight * 2,
    {
      align: "center",
    }
  );
  doc.text(
    t("__cena_netto"),
    MARGIN + HALF_WIDTH + HALF_WIDTH / 8,
    top + MARGIN + textOffset + rowHeight * 2,
    {
      align: "center",
    }
  );
  doc.text(
    t("__rabat"),
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 3) / 8,
    top + MARGIN + textOffset + rowHeight * 2,
    {
      align: "center",
    }
  );
  doc.text(
    t("__cena_netto_po_rabacie"),
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 3) / 4,
    top + MARGIN + textOffset + rowHeight * 2,
    {
      align: "center",
    }
  );
  doc.text(
    t("__podsumowanie"),
    MARGIN + (HALF_WIDTH / 8) * 3,
    top + MARGIN + textOffset + rowHeight * (3 + options.length),
    {
      align: "center",
    }
  );
  doc.text(
    t("__cena_netto_po_rabacie"),
    MARGIN + HALF_WIDTH,
    top + MARGIN + textOffset + rowHeight * (3 + options.length),
    {
      align: "center",
    }
  );
  doc.text(
    t("__stawka_vat"),
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 3) / 8,
    top + MARGIN + textOffset + rowHeight * (3 + options.length),
    {
      align: "center",
    }
  );
  doc.text(
    t("__cena_brutto_rabat"),
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 6) / 8,
    top + MARGIN + textOffset + rowHeight * (3 + options.length),
    {
      align: "center",
    }
  );

  const calculateFinal = (price?: number, discount?: number) => {
    return (price || 0) * (1 - (discount || 0));
  };

  const sum =
    calculateFinal(
      order.payment.variantClientPrice,
      order.payment.variantClientPriceDiscount
    ) +
    calculateFinal(
      order.payment.railExtensionClientPrice,
      order.payment.railExtensionClientPriceDiscount
    ) +
    calculateFinal(
      order.payment.brakeClientPrice,
      order.payment.brakeClientPriceDiscount
    ) +
    calculateFinal(
      order.payment.doorClientPrice,
      order.payment.doorClientPriceDiscount
    ) +
    calculateFinal(
      order.payment.montageClientPrice,
      order.payment.montageClientPriceDiscount
    ) +
    calculateFinal(
      order.payment.transportClientPrice,
      order.payment.transportClientPriceDiscount
    ) +
    calculateFinal(
      order.payment.boxMontageClientPrice,
      order.payment.boxMontageClientPriceDiscount
    );

  const finalWithVat = sum * (1 + Number(order.payment.clientVat));
  doc.text(
    addCurrency(formatPrice(finalWithVat), currencySymbol),
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 3) / 4,
    top + MARGIN + textOffset + rowHeight * (3 + options.length + 1),
    {
      align: "center",
    }
  );

  setDefaultFont(doc, theme);

  doc.text(
    addCurrency(formatPrice(sum), currencySymbol),
    MARGIN + HALF_WIDTH,
    top + MARGIN + textOffset + rowHeight * (3 + options.length + 1),
    {
      align: "center",
    }
  );

  doc.text(
    formatDiscount(order.payment.clientVat),
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 3) / 8,
    top + MARGIN + textOffset + rowHeight * (3 + options.length + 1),
    {
      align: "center",
    }
  );

  doc.text(
    `${t("__model")} ${order.model.label}`,
    MARGIN + 1,
    top + MARGIN + textOffset + rowHeight
  );

  const { price, discount, final } = getPriceWithDiscount(
    order.payment.variantClientPrice,
    order.payment.variantClientPriceDiscount
  );

  doc.text(
    addCurrency(formatPrice(price), currencySymbol),
    MARGIN + HALF_WIDTH + HALF_WIDTH / 8,
    top + MARGIN + textOffset + rowHeight,
    {
      align: "center",
    }
  );
  doc.text(
    formatDiscount(discount),
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 3) / 8,
    top + MARGIN + textOffset + rowHeight,
    {
      align: "center",
    }
  );
  doc.text(
    addCurrency(formatPrice(final), currencySymbol),
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 3) / 4,
    top + MARGIN + textOffset + rowHeight,
    {
      align: "center",
    }
  );

  options.forEach((row, index) =>
    renderPartnerPriceRow(doc, t, row, index, MARGIN + top)
  );
};

const drawClientBasicPrices = (
  doc: jsPDF,
  theme: Theme,
  t: TFunction,
  order: Order,
  top: number,
  options: { label: string }[]
) => {
  const rowHeight = 7;
  const textOffset = 4.5;
  const currencySymbol = CURRENCIES.find(
    (c) => c.code === order.payment.clientCurrency
  )?.symbol;

  doc.rect(MARGIN, top, HALF_WIDTH * 2, rowHeight);

  for (let i = 1; i < 3 + options.length; i++) {
    doc.setDrawColor(theme.palette.grey["300"]);
    doc.rect(MARGIN, top + i * rowHeight, HALF_WIDTH * 2, rowHeight);
  }

  doc.rect(
    MARGIN,
    top + (3 + options.length) * rowHeight,
    HALF_WIDTH / 4,
    rowHeight
  );
  doc.rect(
    MARGIN + HALF_WIDTH * 0.25,
    top + (3 + options.length) * rowHeight,
    HALF_WIDTH / 4,
    rowHeight
  );
  doc.rect(
    MARGIN + HALF_WIDTH * 0.5,
    top + (3 + options.length) * rowHeight,
    HALF_WIDTH / 4,
    rowHeight
  );
  doc.rect(
    MARGIN + HALF_WIDTH * 0.75,
    top + (3 + options.length) * rowHeight,
    HALF_WIDTH / 2,
    rowHeight
  );

  doc.rect(
    MARGIN + HALF_WIDTH * 1.25,
    top + (3 + options.length) * rowHeight,
    HALF_WIDTH / 4,
    rowHeight
  );
  doc.rect(
    MARGIN + HALF_WIDTH * 1.5,
    top + (3 + options.length) * rowHeight,
    HALF_WIDTH / 2,
    rowHeight
  );

  doc.rect(
    MARGIN + HALF_WIDTH * 0.25,
    top + (3 + options.length + 1) * rowHeight,
    HALF_WIDTH / 4,
    rowHeight
  );
  doc.rect(
    MARGIN + HALF_WIDTH * 0.5,
    top + (3 + options.length + 1) * rowHeight,
    HALF_WIDTH / 4,
    rowHeight
  );
  doc.rect(
    MARGIN + HALF_WIDTH * 0.75,
    top + (3 + options.length + 1) * rowHeight,
    HALF_WIDTH / 2,
    rowHeight
  );

  doc.rect(
    MARGIN + HALF_WIDTH * 1.25,
    top + (3 + options.length + 1) * rowHeight,
    HALF_WIDTH / 4,
    rowHeight
  );
  doc.rect(
    MARGIN + HALF_WIDTH * 1.5,
    top + (3 + options.length + 1) * rowHeight,
    HALF_WIDTH / 2,
    rowHeight
  );

  setBoldFont(doc);
  doc.setTextColor(theme.palette.primary.dark);
  doc.text(
    t("__specyfikacja_klient").toUpperCase(),
    MARGIN + HALF_WIDTH,
    top + textOffset,
    {
      align: "center",
    }
  );

  doc.text(
    t("__opcje_dodatkowe").toUpperCase(),
    MARGIN + HALF_WIDTH,
    top + textOffset + rowHeight * 2,
    {
      align: "center",
    }
  );

  doc.text(
    t("__podsumowanie"),
    MARGIN + HALF_WIDTH / 8,
    top + textOffset + rowHeight * (3 + options.length),
    {
      align: "center",
    }
  );
  doc.text(
    t("__cena_netto"),
    MARGIN + (HALF_WIDTH * 3) / 8,
    top + textOffset + rowHeight * (3 + options.length),
    {
      align: "center",
    }
  );
  doc.text(
    t("__rabat"),
    MARGIN + (HALF_WIDTH * 5) / 8,
    top + textOffset + rowHeight * (3 + options.length),
    {
      align: "center",
    }
  );
  doc.text(
    t("__cena_netto_po_rabacie"),
    MARGIN + HALF_WIDTH,
    top + textOffset + rowHeight * (3 + options.length),
    {
      align: "center",
    }
  );
  doc.text(
    t("__stawka_vat"),
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 3) / 8,
    top + textOffset + rowHeight * (3 + options.length),
    {
      align: "center",
    }
  );
  doc.text(
    t("__cena_brutto_rabat"),
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 6) / 8,
    top + textOffset + rowHeight * (3 + options.length),
    {
      align: "center",
    }
  );

  doc.text(
    addCurrency(
      formatPrice(
        (order.payment.clientPrice || 0) * (1 + order.payment.clientVat)
      ),
      currencySymbol
    ),
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 6) / 8,
    top + textOffset + rowHeight * (3 + options.length + 1),
    {
      align: "center",
    }
  );

  setDefaultFont(doc, theme);

  doc.text(
    addCurrency(
      formatPrice(
        (order.payment.clientPrice || 0) /
          (1 - (order.payment.clientDiscount || 0))
      ),
      currencySymbol
    ),
    MARGIN + (HALF_WIDTH * 3) / 8,
    top + textOffset + rowHeight * (3 + options.length + 1),
    {
      align: "center",
    }
  );
  doc.text(
    formatDiscount(order.payment.clientDiscount || 0),
    MARGIN + (HALF_WIDTH * 5) / 8,
    top + textOffset + rowHeight * (3 + options.length + 1),
    {
      align: "center",
    }
  );

  doc.text(
    addCurrency(formatPrice(order.payment.clientPrice || 0), currencySymbol),
    MARGIN + HALF_WIDTH,
    top + textOffset + rowHeight * (3 + options.length + 1),
    {
      align: "center",
    }
  );

  doc.text(
    formatDiscount(order.payment.clientVat || 0),
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 3) / 8,
    top + textOffset + rowHeight * (3 + options.length + 1),
    {
      align: "center",
    }
  );

  doc.text(
    `${t("__model")} ${order.model.label}`,
    MARGIN + 1,
    top + textOffset + rowHeight
  );

  options.forEach((row, index) =>
    renderClientPriceRow(doc, t, row.label, index, top)
  );
};

const renderClientPrices = (
  doc: jsPDF,
  theme: Theme,
  t: TFunction,
  order: Order,
  top: number
) => {
  const options = [];
  if (order.railLength > 0) {
    const row = {
      label: t("__przedluzenie_szyn"),
    };
    options.push(row);
  }
  if (isAnythingOnFront(order)) {
    const row = {
      label: `${t("__dodatkowe_drzwi")} - ${t(
        "__sciana_przednia"
      ).toLowerCase()}`,
    };
    options.push(row);
  }
  if (isAnythingOnBack(order)) {
    const row = {
      label: `${t("__dodatkowe_drzwi")} - ${t(
        "__sciana_tylnia"
      ).toLowerCase()}`,
    };
    options.push(row);
  }
  if (order.brakeKey) {
    const row = {
      label: t("__kluczyk_blokady"),
    };
    options.push(row);
  }
  if (order.withMontage) {
    const row = {
      label: t("__montaz"),
    };
    options.push(row);
  }
  if (order.withTransport) {
    const row = {
      label: t("__transport"),
    };
    options.push(row);
  }
  if (order.box !== undefined && !order.box) {
    const row = {
      label: t("__zlozenie"),
    };
    options.push(row);
  }

  drawClientBasicPrices(doc, theme, t, order, top, options);
};

const renderPartnerPriceRow = (
  doc: jsPDF,
  t: TFunction,
  row: { label: string; price: string; discount: string; finalPrice: string },
  index: number,
  top: number
) => {
  const rowHeight = 7;
  const textOffset = 4.5;

  doc.text(row.label, MARGIN + 1, top + textOffset + rowHeight * (3 + index));
  doc.text(
    row.price,
    MARGIN + HALF_WIDTH + HALF_WIDTH / 8,
    top + textOffset + rowHeight * (3 + index),
    {
      align: "center",
    }
  );
  doc.text(
    row.discount,
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 3) / 8,
    top + textOffset + rowHeight * (3 + index),
    {
      align: "center",
    }
  );
  doc.text(
    row.finalPrice,
    MARGIN + HALF_WIDTH + (HALF_WIDTH * 3) / 4,
    top + textOffset + rowHeight * (3 + index),
    {
      align: "center",
    }
  );
};

const renderClientPriceRow = (
  doc: jsPDF,
  t: TFunction,
  label: string,
  index: number,
  top: number
) => {
  const rowHeight = 7;
  const textOffset = 4.5;

  doc.text(label, MARGIN + 1, top + textOffset + rowHeight * (3 + index));
};

const renderThanks = (doc: jsPDF, t: TFunction, theme: Theme, top: number) => {
  setBoldFont(doc);
  doc.text(`${t("__podziekowanie")}!`, MARGIN + HALF_WIDTH, top, {
    align: "center",
  });
  setDefaultFont(doc, theme);
};

const renderFooter = (
  doc: jsPDF,
  theme: Theme,
  t: TFunction,
  top: number,
  hideBucovers: boolean
) => {
  const height = 25;
  doc.setDrawColor(theme.palette.grey["300"]);
  doc.rect(MARGIN, top, HALF_WIDTH, height);
  !hideBucovers && doc.rect(MARGIN + HALF_WIDTH, top, HALF_WIDTH, height);
  doc.text(t("__potwierdzenie_zamowienia"), MARGIN + HALF_WIDTH / 2, top + 20, {
    align: "center",
  });
  doc.text(t("__pieczatka"), MARGIN + HALF_WIDTH / 2, top + 23, {
    align: "center",
  });
  !hideBucovers &&
    doc.addImage(
      pdfBottomLogo,
      "png",
      MARGIN + HALF_WIDTH + (HALF_WIDTH - 57) / 2,
      top + 2.5,
      57,
      20
    );
};

const downloadPdf = (doc: jsPDF, order: Order, t: TFunction) => {
  const fileName = `${t("OFFER")} ${order.id} - ${order.partner.name}.pdf`;
  doc.save(fileName);
};

const setDefaultFont = (doc: jsPDF, theme: Theme) => {
  setRegularFont(doc);
  doc.setFontSize(DEFAULT_FONT_SIZE);
  doc.setTextColor(theme.palette.common.black);
};

const setRegularFont = (doc: jsPDF) => {
  doc.setFont("Roboto", "normal");
};

const setBoldFont = (doc: jsPDF) => {
  doc.setFont("Roboto", "bold");
};

const getFrontPhotoType = (order: Order): PhotoType => {
  if (order.doorType.includes(DoorEnum.FrontMain) && order.model.doorPhoto) {
    return "doorPhoto";
  }

  if (order.clapType.includes(ClapEnum.Front) && order.model.flapPhoto) {
    return "flapPhoto";
  }

  return "defaultPhoto";
};

const getBackPhotoType = (order: Order): PhotoType => {
  if (order.doorType.includes(DoorEnum.BackMain)) {
    if (order.secondaryHeight && order.model.secondaryDoorPhoto) {
      return "secondaryDoorPhoto";
    }

    if (order.model.doorPhoto) {
      return "doorPhoto";
    }
  }

  if (order.clapType.includes(ClapEnum.Back)) {
    if (order.secondaryHeight && order.model.secondaryFlapPhoto) {
      return "secondaryFlapPhoto";
    }

    if (order.model.flapPhoto) {
      return "flapPhoto";
    }
  }

  return "defaultPhoto";
};

const addSemicolon = (value: string) => `${value}:`;

const addCurrency = (value: string, currencySymbol: string = "€") =>
  `${value} ${currencySymbol}`.replace(String.fromCharCode(8239), " ");

const checkDoorsOnTop = (doorType: DoorEnum[]) => {
  const topOptions: DoorEnum[] = [
    DoorEnum.FrontRight,
    DoorEnum.BackRight,
    DoorEnum.BackLeft,
    DoorEnum.FrontLeft,
  ];

  return doorType.some((x) => topOptions.includes(x));
};

const getFrontPrice = (
  areDoorsOnTop: boolean,
  isAnythingOnFront: boolean,
  isAnythingOnBack: boolean,
  doorPrice: number,
  doorDiscount: number
) => {
  if (areDoorsOnTop && isAnythingOnFront) {
    if (isAnythingOnBack) {
      return [doorPrice / 2, doorDiscount / 2];
    }

    return [doorPrice, doorDiscount];
  }

  return [0, 0];
};

const getBackPrice = (
  areDoorsOnTop: boolean,
  isAnythingOnFront: boolean,
  isAnythingOnBack: boolean,
  doorPrice: number,
  doorDiscount: number
) => {
  if (isAnythingOnBack && (areDoorsOnTop || isAnythingOnFront)) {
    if (areDoorsOnTop) {
      if (isAnythingOnFront) {
        return [doorPrice / 2, doorDiscount / 2];
      }
      return [doorPrice, doorDiscount];
    }
    if (isAnythingOnFront) {
      return [doorPrice, doorPrice - doorDiscount];
    }
  }

  return [0, 0];
};

const isAnythingOnFront = (order: Order) =>
  order.doorType.includes(DoorEnum.FrontMain) ||
  order.clapType.includes(ClapEnum.Front);

const isAnythingOnBack = (order: Order) =>
  order.doorType.includes(DoorEnum.BackMain) ||
  order.clapType.includes(ClapEnum.Back);

const getFrontDoorPrice = (order: Order) => {
  const { doorType, payment } = order;
  const areDoorsOnTop = checkDoorsOnTop(doorType);
  const isOnFront = isAnythingOnFront(order);
  const isOnBack = isAnythingOnBack(order);

  const [frontPrice, frontDiscount] = getFrontPrice(
    areDoorsOnTop,
    isOnFront,
    isOnBack,
    payment.doorPrice,
    payment.doorPriceDiscount
  );
  const [backPrice, backDiscount] = getBackPrice(
    areDoorsOnTop,
    isOnFront,
    isOnBack,
    payment.doorPrice,
    payment.doorPriceDiscount
  );

  return { frontPrice, frontDiscount, backPrice, backDiscount };
};

const getFrontDoorDetailedPrice = (order: Order) => {
  const { doorType, payment } = order;
  const areDoorsOnTop = checkDoorsOnTop(doorType);
  const isOnFront = isAnythingOnFront(order);
  const isOnBack = isAnythingOnBack(order);

  const [frontPrice, frontDiscount] = getFrontPrice(
    areDoorsOnTop,
    isOnFront,
    isOnBack,
    payment.doorClientPrice || 0,
    payment.doorClientPriceDiscount || 0
  );
  const [backPrice, backDiscount] = getBackPrice(
    areDoorsOnTop,
    isOnFront,
    isOnBack,
    payment.doorClientPrice || 0,
    payment.doorClientPriceDiscount || 0
  );

  return { frontPrice, frontDiscount, backPrice, backDiscount };
};
