import { Address } from "../types/partner-types";
import { Address as ClientAddress } from "../redux/slices/order-slice";

export const formatAddress = (address: Address) => {
  const { flatNumber, houseNumber, street, zipCode, city } = address;
  const number = flatNumber ? `${houseNumber}/${flatNumber}` : houseNumber;
  return `${street} ${number}, ${zipCode} ${city}`;
};

export const formatClientAddress = (address: ClientAddress) => {
  const { flatNumber, houseNumber, street, zipCode, city } = address;
  const number = flatNumber ? `${houseNumber}/${flatNumber}` : houseNumber;
  const streetPart = street ? `${street} ${number}, ` : "";
  return `${streetPart}${zipCode} ${city}`;
};

export const formatCountry = (address: Address) => {
  return address.countryId;
};

export const formatDiscount = (discount: number) => `${discount * 100}%`;

export const formatDate = (date: string) => new Date(date).toLocaleDateString();

export const formatPrice = (value: number) => {
  return value.toLocaleString("fr-FR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatInteger = (value: number) => {
  return value.toLocaleString("fr-FR", {
    maximumFractionDigits: 0,
  });
};
