export const deMainTranslations = {
  konfiguracja: "Konfiguration",
  zadaszenia: "Schwimmbadüberdachungen",
  aktualnosci: "Neuheiten",
  zamowienia: "Bestellungen",
  partnerzy: "Partner",
  cennik: "Preisliste",
  uzytkownicy: "Benutzer",
  niepoprawny_mail: "Bitte korrekte E-Mail-Adresse angeben",
  brak_maila: "E-Mail-Adresse erforderlich",
  brak_hasla: "Passwort erforderlich",
  brak_starego_hasla: "Altes Passwort erforderlich",
  brak_nowego_hasla: "Neues Passwort erforderlich",
  brak_potwierdzenia_hasla: "Passwortwiederholung erforderlich",
  login_zacheta:
    "Geben Sie Ihr Passwort und E-Mail-Adresse an, um sich einzuloggen.",
  login: "Einloggen",
  mail: "E-mail",
  haslo: "Passwort",
  stare_haslo: "Altes Passwort",
  nowe_haslo: "Neues Passwort",
  potwierdz_haslo: "Passwort bestätigen",
  niepoprawne_dane: "Fehler bei der Dateneintragung",
  blad_uwierzytelniania: "Sitzung abgelaufen",
  nieodnaleziono: "Ressource nicht gefunden",
  blad_serwera: "Serverfehler",
  wystapil_nieoczekiwany_blad: "Es ist zu einem unerwarteten Fehler gekommen",
  nieoczekiwany_blad_tresc:
    "Bei der Bearbeitung des Befehls kam es zu einem unerwarteten Fehler. Wiederholt sich das Problem nach dem Auffrischen der Webseite, treten Sie mit dem Service in Kontakt und geben Sie die Fehler-Kennung an.",
  identyfikator_bledu: "Fehler-Kennung",
  zamknij: "Schließen",
  ups: "Ups! Etwas ging schief…",
  ups_tresc:
    "Die Webseite konnte nicht richtig geladen werden. Wiederholt sich das Problem nach dem Auffrischen der Webseite, treten Sie mit dem Service in Kontakt.",
  zmiana_hasla_zacheta: "Neues Passwort für Benutzerkonto erstellen",
  zapisz: "Speichern",
  powrot: "Zurück",
  rozne_hasla: "Passwörter sind nicht identisch",
  haslo_min: "Das Passwort muss mind. acht Zeichen haben.",
  haslo_max: "Das Passwort darf nicht mehr als 32 Zeichen haben.",
  slabe_haslo:
    "Dass Passwort muss ein Kleinbuchstabe, ein Großbuchstabe, eine Ziffer und ein Sonderzeichen umfassen.",
  polityka_prywatnosci: "Datenschutzerklärung",
  zaakceptuj: "Akzeptieren",
  anuluj: "Abbrechen",
  polityka_prywatnosci_tekst: "Datenschutzerklärung",
  liczba_wierszy: "Zahl der Reihen",
  status: "Status",
  aktywny: "Aktiv",
  dezaktywowany: "Deaktiviert",
  kraj: "Land",
  dodaj: "Hinzufügen",
  empty_table: "Es gibt keine Daten fuer die angegebenen Parameter",
  edytuj: "Editieren",
  aktywuj: "Aktivieren",
  dezaktywuj: "Deaktivieren",
  nazwa: "Bezeichnung",
  adres: "Anschrift",
  znizka: "Rabatt",
  potwierdz_operacje: "Vorgang bestätigen",
  dezaktywacja_partnera_pytanie: "Wollen Sie diesen Partner deaktivieren?",
  aktywacja_partnera_pytanie: "Wollen Sie diesen Partner aktivieren?",
  edycja_partnera: "Partner bearbeiten",
  nowy_partner: "Neuer Partner",
  dane_partnera: "Partnerdaten",
  kierunkowy: "Vorwahl",
  numer_telefonu: "Telefonnummer",
  darmowe_przedluzenie_szyn: "Kostenfreie Schienenverlängerung",
  dane_adresowe: "Addressdaten",
  ulica: "Straße",
  numer_domu: "Hausnummer",
  numer_lokalu: "Wohnung",
  kod_pocztowy: "PLZ",
  miasto: "Stadt",
  wymagane_pole: "Pflichtfeld",
  niepoprawna_wartosc: "Falscher Wert",
  zapisane_zmiany: "Änderungen gespeichert",
  dezaktywacja_uzytkwnika_pytanie: "Wollen Sie diesen Benuzter deaktivieren?",
  aktywacja_uzytkownika_pytanie: "Wollen Sie diesen Benuzter aktivieren?",
  rola: "Rolle",
  ROLE_ADMIN: "Administrator",
  ROLE_PARTNER: "Partner",
  brak: "Keine",
  edycja_usera: "Benutzer bearbeiten",
  nowy_user: "Neuer Benutzer",
  dane_usera: "Benutzerdaten",
  imie: "Vorname",
  nazwisko: "Name",
  partner: "Partner",
  data_dodania: "Datum",
  tytul: "Titel",
  tresc: "Inhalt",
  dezaktywacja_posta_pytanie: "Wollen Sie diese Anzeige deaktivieren?",
  aktywacja_posta_pytanie: "Wollen Sie diese Anzeige aktivieren?",
  polski: "Polnisch",
  angielski: "Englisch",
  niemiecki: "Deutsch",
  szwedzki: "Schwedisch",
  dane_podstawowe: "Grunddaten",
  nowy_post: "Neue Anzeige",
  edytcja_posta: "Anzeige bearbeiten",
  kolory: "Farben",
  blokady: "Schlösser",
  wypelnienia: "Füllungen",
  kategorie: "Kategorien",
  profile: "Profile",
  edycja_blokady: "Schloss bearbeiten",
  nowa_blokada: "Neuer Schloss",
  polska_nazwa: "Polnische Bezeichnung",
  angielska_nazwa: "Englische Bezeichnung",
  niemiecka_nazwa: "Deusche Bezeichnung",
  szwedzka_nazwa: "Schwedische Bezeichnung",
  dezaktywacja_blokady_pytanie: "Wollen Sie diesen Schloss deaktivieren?",
  aktywacja_blokady_pytanie: "Wollen Sie diesen Schloss aktivieren?",
  __edycja_koloru: "Farbe bearbeiten",
  __nowy_kolor: "Neue Farbe",
  __dezaktywacja_koloru_pytanie: "Wollen Sie diese Farbe deaktivieren?",
  __aktywacja_koloru_pytanie: "Wollen Sie diese Farbe aktivieren?",
  __edycja_wypelnienia: "Füllung bearbeiten",
  __nowe_wypelnienie: "Neue Füllung",
  __dezaktywacja_wypelnienia_pytanie: "Wollen Sie diese Füllung deaktivieren?",
  __aktywacja_wypelnienia_pytanie: "Wollen Sie diese Füllung aktivieren?",
  __edycja_profilu: "Profil bearbeiten",
  __nowy_profil: "Neues Profil",
  __dezaktywacja_profilu_pytanie: "Wollen Sie dieses Profil deaktivieren?",
  __aktywacja_profilu_pytanie: "Wollen Sie dieses Profil aktivieren?",
  __edycja_kategorii: "Kategorie bearbeiten",
  __nowa_kategoria: "Neue Kategorie",
  __dezaktywacja_kategorii_pytanie: "Wollen Sie diese Kategorie deaktivieren?",
  __aktywacja_kategorii_pytanie: "Wollen Sie diese Kategorie aktivieren?",
  __cena__przedluzenia_w_profilu: "Preis pro Meter Schienenverlängerung",
  __dostepne_blokady: "Verfügbare Schlösser",
  __wszystkie_blokady: "Alle Schlösser",
  __brak_opcji: "Keine Optionen",
  __wybierz_dostepne_blokady: "Verfügbare Schlösser wählen",
  __dostepne_kolory: "Verfügbare Farben",
  __wszystkie_kolory: "Alle Farben",
  __wybierz_dostepne_kolory: "Verfügbare Farben wählen",
  __dostepne_wypelnienia: "Verfügbare Füllungen",
  __wszystkie_wypelnienia: "Alle Füllungen",
  __wybierz_dostepne_wypelnienia: "Verfügbare Füllungen wählen",
  __dostepne_drzwi: "Verfügbare Türen",
  __wszystkie_drzwi: "Alle Türen",
  __drzwi: "Tür",
  __wybierz_dostepne_drzwi: "Verfügbare Türen wählen",
  FRONT_MAIN: "Vordertür",
  BACK_MAIN: "Hintertür",
  FRONT_RIGHT: "Vorne rechts",
  FRONT_LEFT: "Vorne links",
  BACK_RIGHT: "Hinten rechts",
  BACK_LEFT: "Hinten links",
  __cena: "Preis",
  DOOR: "Tür",
  __ceny_podstawowe: "Grundpreise",
  __edycja_ceny: "Preis bearbeiten",
  __dodawanie_ceny_moduly: "Neuer Preis hängt von den Modulen ab",
  __edycja_ceny_moduly: "Modulabhängigen Preis bearbeiten",
  __liczba_modulow: "Anzahl der Module",
  __cena_blokady: "Schlosspreis",
  __cena_montazu: "Montagepreis",
  __ceny_moduly: "Von Modulzahl abhängige Preise",
  FRONT: "Vorderklappe",
  BACK: "Hinterklappe",
  __dostepne_klapy: "Verfügbare Klappen",
  __wszystkie_klapy: "Alle Klappen",
  __klapy: "Klappen",
  __wybierz_dostepne_klapy: "Verfügbare Klappen wählen",
  __edycja_zadaszenia: "Überdachung bearbeiten",
  __nowe_zadaszenie: "Neue Überdachung",
  __warianty_zadaszenia: "Varienten der Überdachungen",
  __rendery_zadaszenia: "Abbildungen der Überdachung",
  __pozycja_na_liscie: "Zahl auf der Liste",
  __kategoria: "Kategorie",
  __profil: "Profil",
  __liczba_szyn: "Anzahl Schienen",
  __domyslny: "Voreingestellt",
  __z_drzwiami: "Mit Tür",
  __z_klapa: "Mit Klappe",
  __kliknij_zdjecie: "Klicken Sie, um ein Bild zu wählen",
  __nowy_wariant: "Neue Variante",
  __edycja_wariantu: "Variante bearbeiten",
  __szerokosc_zewnetrzna: "äußere Breite",
  __szerokosc_wewnetrzna: "innere Breite",
  __szerokosc_zewnetrzna_long: "äußere Breite",
  __szerokosc_wewnetrzna_long: "innere Breite",
  __dlugosc: "Länge",
  __wysokosc: "Höhe",
  __standardowa_dlugosc: "Standardlänge",
  __model: "Modell",
  __ceny_wariantu: "Variantpreise",
  __wszystkie_istniejace: "Alle bestehenden",
  __wszystkie_z_katerogii: "Alle in der Kategorie",
  __wszystkie_z_modelu: "Alle für das Modell",
  __brak_dostepnych_wypelnien: "Keine Füllungen verfügbar",
  __wypelnienie: "Füllung",
  __aktywna: "Aktiv",
  __dezaktywowana: "Deaktiviert",
  __aktywacja_variantu_pytanie: "Wollen Sie diese Variante deaktivieren?",
  __dezaktywacja_variantu_pytanie: "Wollen Sie diese Variante aktivieren?",
  __moduly: "Module",
  __aktywacja_zadaszenia_pytanie: "Wollen Sie diese Überdachung aktivieren?",
  __dezaktywacja_zadaszenia_pytanie:
    "Wollen Sie diese Überdachung deaktivieren?",
  __niezapisane_zmiany: "Nicht gespeicherte Änderungen",
  __porzuc_zmiany: "Rückgängig machen",
  __porzucone_zmiany_pytanie:
    "Im Formular sind nicht gespeicherte Änderungen enthalten, wollen Sie diese verwerfen?",
  TRANSPORT_WITHOUT_MONTAGE: "Transport ohne Montage (Preis pro km)",
  TRANSPORT_WITH_MONTAGE: "Transport mit Montage (Preis pro km)",
  __wybor_modelu: "Modell wählen",
  __nowa_oferta: "Neues Angebot",
  __wybrany_model: "Gewähltes Modell",
  __wymiary_zadaszenia: "Abmessungen der Überdachung",
  __dlugosci_niestandardowe: "Sonderlängen",
  __konfiguracja_modelu: "Modellkonfiguration",
  __kolor: "Farbe",
  __przedluzenie_szyn: "Schienenverlängerung",
  __blokada: "Schloss",
  __rodzaj_podloza: "Art des Bodens",
  __domyslne_podwyzszenie_scian: "Voreingestellte Wanderhöhung",
  __podwyzszenie_scian: "Wanderhöhung",
  __standardowe: "Standard",
  __kluczyk_blokady: "Schlüssel",
  __podwyzszenie_scian_tooltip:
    "Ist die ausgewählte Wanderhöhung anders als die standardmäßige Wanderhöhung, beeinflusst dies den Preis nicht. Dadurch wird aber die Wahl der Klappe in der Vorder- und Hinterwand gesperrt. Wurde eine Klappe in der Vorder- und Hinterwand gewählt, wird die Option der Änderung des Spielraums gesperrt.",
  __dane_klienta: "Kundendaten",
  __oferta_dla_klienta: "Angebot für den Kunden",
  __konfiguracja_drzwi: "Türkonfiguration",
  __sciana_przednia: "Vorderwand",
  __sciana_tylnia: "Hinterwand",
  __gora_zadaszenia: "Oberseite der Überdachung",
  __drzwi_na_dachu_tooltip:
    "Der Überdachungspreis umfasst eine Einzeltür im größten Modul oder in der größten Wand. Weitere Türkonfigurationen sind mit Aufpreis möglich. Die Modelle Gravity und Cosmos sind einmalig - sie haben keine Türen.",
  __montaz_i_transport: "Montage und Transport",
  __dostawa: "Lieferung",
  __obior_osobisty: "Persönliche Abholung",
  __transport_bucovers: "Transport durch Bucovers",
  __montaz_transport_bucovers: "Transport und Montage durch Bucovers",
  __odleglosc: "Entfernung",
  __szczegoly_platnosci: "Zahlungsdetails",
  __przyznany_rabat: "Rabatt zugewiesen",
  __tooltip_rabat: "Rabatt umfasst die Transport- und Montagekosten nicht",
  __cena_przed_rabatem: "Bucovers-Nettopreis vor Rabatt",
  __cena_po_rabacie: "Bucovers-Nettopreis mit Rabatt",
  __cena_dla_klienta_przed_rabatem: "Kunden-Nettopreis vor Rabatt",
  __rabat_dla_klienta: "Kundenrabatt",
  __cena_dla_klienta_po_rabacie: "Kunden-Nettopreis mit Rabatt",
  __wszystkie: "Alle",
  __oferty: "Angebote",
  WAITING_FOR_ACCEPT: "Zustimmung ausstehend",
  ACCEPTED: "Akzeptiert",
  DELIVERED: "Geliefert",
  CANCELED: "Abgebrochen",
  DELETED: "Gelöscht",
  EXPIRED: "Überfällig",
  __klient: "Kunde",
  __numer_oferty: "Nummer",
  __data_utworzenia: "Datum",
  __szczegoly: "Einzelheiten",
  __zamow: "Bestellen",
  __termin_dostawy: "Geschätztes Lieferdatum",
  OFFER: "Angebot",
  __szczegoly_oferty: "Einzelheiten des Angebots Nr.",
  __szczegoly_zamowienia: "Einzelheiten des Auftrags Nr.",
  __nowe_zamowienie: "Neuer Auftrag",
  __usun: "Löschen",
  __usunac_oferte: "Entfernen? Angebot Nr.",
  __anulowac_zamowienie: "Entfernen? Auftrag Nr.",
  __tak: "Ja",
  BOX_MONTAGE: "Box-Montage",
  __rzut_zadaszenia: "Skizze der Überdachung",
  __pobierz: "Herunterladen",
  __export_header: "Bucovers - Hersteller von Schwimmbadüberdachungen",
  __oferta_przygotowana_przez: "Das Angebot wurde erstellt von",
  __oferta_wazna: "Angebot gültig für",
  __dni: "Tage",
  __dostawca: "Lieferant",
  __odbiorca: "Empfänger",
  __model_zadaszanie: "Modell der Überdachung",
  __oznaczenie_produkcji: "Produktionsbezeichnung",
  __dlugosc_zadaszenia: "Überdachungslänge",
  __wysokosc_zadaszenia: "Überdachungshöhe",
  __rozstaw_wewnetrzny: "Abstand Innenschienen",
  __rozstaw_zewnetrzny: "Abstand Außenschienen",
  __szerokosc_szyn: "Schienenbreite",
  __podwyzszenie_sciany: "Wanderhöhung",
  __klapa: "Klappe",
  __komentarze: "Bemerkungen",
  __specyfikacja_partner: "Technische Daten für den Partner",
  __specyfikacja_klient: "Technische Daten für den Kunden",
  __cena_netto: "Nettopreis",
  __rabat: "Rabatt",
  __cena_netto_po_rabacie: "Nettopreis nach Rabatt",
  __opcje_dodatkowe: "Zusätzliche Optionen",
  __podsumowanie: "Zusammenfassung",
  __dodatkowe_drzwi: "Zusätzliche Tür",
  __montaz: "Installation",
  __transport: "Transport",
  __podziekowanie: "Vielen Dank für Ihr Interesse an unserem Angebot",
  __potwierdzenie_zamowienia: "Auftragsbestätigung durch den Kunden",
  __pieczatka: "Stempel, Datum und Unterschrift",
  __ulica_skrot: "Straße",
  __pozycja_szyny: "Schienenlage",
  RIGHT: "Rechts",
  LEFT: "Links",
  __tel: "Tel.-Nr.",
  __zmiana_statusu: "Status ändern für Auftrag Nr.",
  __z: "von",
  __na: "auf",
  __brak_powiadomien: "Keine Benachrichtigungen",
  __zmiany: "Änderungen wurden eingeführt",
  __box: "Box",
  __zlozenie: "Montage der Überdachung",
  __przedluzenie_tooltip:
    "Überdachungen der Box-Kategorie umfassen eine Schienenverlängerung von 220 cm. Kosten fallen nur bei größeren Verlängerungen an.",
  __zlozenie_tooltip:
    "Wählen Sie die Option der Montage der Schwimmbadüberdachung, so wird die Montage automatisch angewählt.",
  __globalna_zmiana: "Globale Preisänderung",
  __zmien: "Änderung",
  __procentowa_zmiana: "Prozentsatz der Änderung",
  __powieksz: "Vergrößern",
  __zmniejsz: "Reduzieren",
  __zaznacz_wszystkie: "Alle wählen",
  __waluta_klienta: "Kundenwährung",
  __stawka_vat: "MwSt.-Satz",
  __cena_brutto: "Bruttopreis für Kunde",
  EUR: "Euro",
  PLN: "Zloty",
  GBP: "Pfund Sterling",
  SEK: "Schwedische Krone",
  NOK: "Norwegische Krone",
  USD: "US Dollar",
  CHF: "Schweizer Franken",
  CZK: "Tschechische Krone;",
  HUF: "Forint",
  DKK: "Dänische Krone",
  __cena_brutto_rabat: "Bruttopreis nach Ermäßigung",
  __brak_wymaganych_danych: "Die erforderlichen Daten wurden nicht eingetragen",
  __uzupelnij_brakujace_dane:
    "Um das Formular zu speichern, geben Sie bitte die fehlenden Daten an.",
  __cena_nie_uwzglednia:
    "Preis umfasst keine Fährenkosten und sonstige Sondergebühren.",
  __rzut_blad:
    "Die Schienenverlängerung ist zu lang um eine Grafik der Überdachung zu erstellen. Wählen Sie eine Schienenverlängerung von weniger als 1000 cm.",
  __koszt_montazu: "Montagekosten",
  __dodane_przez: "Hinzugefügt durch",
  __termin_tooltip:
    "Die Bestellung wird in der Woche geliefert, die das gewählte Datum umfasst.",
  __eksport_dla_klienta: "Export für Kunde",
  __eksport_dla_klienta_bucovers: "Export für Bucovers-Kunde",
  __eksport_bucovers: "Bucovers-Export",
  __rodzaj_eksportu: "Wählen Sie bitte die Exportart",
  __eksport_bucovers_opis:
    "Bucovers-Export umfasst vollständige Angaben über das Angebot für unsere Geschäftspartner.",
  __eksport_dla_klienta_opis:
    "Kundenexport wurde im Hinblick auf Kunden der Geschäftspartner vorbereitet.",
  __eksport_dla_klienta_bucovers_opis:
    "Bucovers-Kundenexport umfasst ein Dokument für Bucovers-Einzelkunden.",
  __druga_wysokosc: "Zusätzliche Höhe",
  __dodatkowe_zdjecia: "Zusätzliche Visualisierung",
  __dodatkowe_z_drzwiami: "Extra Visualisierung mit Tür",
  __dodatkowe_z_klapa: "Extra Visualisierung mit Klappe",
  __rozbicie_ceny: "Preisaufschlüsselung",
  __cena_wariantu_dla_klienta_przed_rabatem:
    "Der Nettopreis der Variante für den Kunden vor dem Rabatt",
  __rabat_na_wariant_dla_klienta: "Rabatt auf die Variante für den Kunden",
  __cena_wariantu_dla_klienta_po_rabacie:
    "Der Nettopreis der Variante für den Kunden nach dem Rabatt",
  __cena_przedluzenia_szyn_dla_klienta_przed_rabatem:
    "Nettopreis der Schienenverlängerung für den Kunden vor Rabatt",
  __rabat_na_przedluzenie_szyn_dla_klienta:
    "Schienenverlängerung Rabatt für den Kunden",
  __cena_przedluzenia_szyn_dla_klienta_po_rabacie:
    "Nettopreis der Schienenverlängerung für den Kunden nach dem Rabatt",
  __cena_kluczyka_blokady_dla_klienta_przed_rabatem:
    "Nettopreis des Schlossschlüssels für den Kunden vor Rabatt",
  __rabat_na_kluczyk_blokady_dla_klienta:
    "Rabatt auf den Schlossschlüssel für den Kunden",
  __cena_kluczyka_blokady_dla_klienta_po_rabacie:
    "Nettopreis des Schlossschlüssels für den Kunden nach Rabatt",
  __cena_drzwi_dla_klienta_przed_rabatem:
    "Nettopreis der Tür für den Kunden vor Rabatt",
  __rabat_na_drzwi_dla_klienta: "Türrabatt für den Kunden",
  __cena_drzwi_dla_klienta_po_rabacie:
    "Nettopreis der Tür für den Kunden nach Rabatt",
  __cena_transportu_dla_klienta_przed_rabatem:
    "Nettopreis der Transport für den Kunden vor Rabatt",
  __rabat_na_transport_dla_klienta: "Rabatt auf den Transport für den Kunden",
  __cena_transportu_dla_klienta_po_rabacie:
    "Nettopreis des Transports für den Kunden nach Rabatt",
  __cena_montazu_dla_klienta_przed_rabatem:
    "Netto-Installationspreis für Kunden vor Rabatt",
  __rabat_na_montaz_dla_klienta: "Installationsrabatt für den Kunden",
  __cena_montazu_dla_klienta_po_rabacie:
    "Netto-Installationspreis für Kunden nach Rabatt",
  __cena_zlozenia_zadaszenia_dla_klienta_przed_rabatem:
    "Nettopreis für die Montage des Daches für den Kunden vor dem Rabatt",
  __rabat_na_zlozenie_zadaszenia_dla_klienta:
    "Rabatt für die Montage eines Daches für den Kunden",
  __cena_zlozenia_zadaszenia_dla_klienta_po_rabacie:
    "Nettopreis für die Montage des Daches für den Kunden nach Rabatt",
  __cena_dla_klienta: "Nettopreis für den Kunden",
};
